.overflow-y-scroll{
  width: 100% !important;
  min-width: max-content !important;
  height: 100% !important;
}

.report-form {
  .form-label {
    color: var(--foreground-color) !important;
    margin-bottom: 0.5rem;
    font-family: "Poppins", sans-serif !important;
    font-size: 14px !important;
    line-height: 18px !important;

    &.text-white {
      color: var(--white-color) !important;
    }
  }

  .radio {
    margin: 0.5rem;

    .form-check-input {
      position: absolute;
      margin-top: 4px;
      opacity: 0;

      +.form-check-label {
        &:before {
          content: '';
          background: var(--border-new-color);
          border-radius: 100%;
          border: 1px solid var(--border-new-color);
          display: inline-block;
          width: 1.4em;
          height: 1.4em;
          position: relative;
          margin-right: 1em;
          vertical-align: top;
          cursor: pointer;
          text-align: center;
          transition: all 250ms ease;
        }
      }

      &:checked {
        +.form-check-label {

          // color: var(--foreground-color);
          &:before {
            background-color: var(--foreground-color);
            box-shadow: inset 0 0 0 4px var(--border-new-color);
          }
        }
      }

      &:focus {
        +.form-check-label {
          &:before {
            outline: none;
            border-color: transparent;
          }
        }
      }

      &:disabled {
        +.form-check-label {
          &:before {
            box-shadow: inset 0 0 0 4px var(--border-new-color);
            border-color: var(--border-new-color);
            background: var(--border-new-color);
          }
        }
      }

      +.form-check-label {
        &:empty {
          &:before {
            margin-right: 0;
          }
        }
      }
    }
  }

}

.react-datepicker-wrapper {
  width: 95% !important;
}

.react-datepicker__day--selected {
  background-color: var(--foreground-color) !important;
}

.react-datepicker__triangle {
  transform: translate(140px, 0px) !important;
}

.report-select option {
  margin-top: 4px !important;
  color: var(--white-color) !important;
  background-color: var(--ag-grid-header-color) !important;
  cursor: pointer !important;
  font-family: "Assistant", sans-serif !important;
  font-size: 14px !important;
  line-height: 18px !important;
}

.custom-search-user-table {
  .css-b62m3t-container {
    border: 0.2px solid var(--white-color) !important;
    border-radius: 5px !important;
    box-shadow: none !important;

    .css-13cymwt-control {
      background-color: var(--background-color) !important;
    }
    
    .css-t3ipsp-control{
      box-shadow: none !important;
      border: 0.2px solid var(--foreground-color) !important;
      background-color: var(--background-color) !important;
      border-radius: 5px !important;
    }

    .css-t3ipsp-control, .css-13cymwt-control{
      height: 35px !important;
    }
  }
  .css-1fdsijx-ValueContainer,
  .css-qbdosj-Input,
  .css-qbdosj-Input>input,
  .css-166bipr-Input,
  .css-166bipr-Input>input {
    color: var(--white-color) !important;
    font-size: 15px !important;
    text-wrap: nowrap !important;
  }
}

.css-1nmdiq5-menu, .css-1nmdiq5-menu > div{
  width: 100% !important;
}

.css-1nmdiq5-menu{
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  max-height: 300px !important;
  position: absolute !important;
}
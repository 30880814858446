@import "../../variables.scss";

.order-blotter {
  .orderheader {
    min-width: max-content !important;
  }
  .list-container {
    margin: 5px;
  }

  .order-export-btn {
    width: 100%;
    height: 100%;
    font-family: "Assistant", sans-serif !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    background-color: var(--toolbar-bg-color);
    color: var(--foreground-color);
    padding: 0px;
    border: 0.5px solid var(--foreground-color);
    border-radius: 4px;
    margin: 4px 7px !important;
  }

  .order-export-btn:hover,
  .order-export-btn:focus,
  .order-export-btn:active {
    color: var(--foreground-color);
    background-color: var(--white-color) !important;
    border-color: var(--foreground-color);
    box-shadow: none !important;
    opacity: 0.9 !important;
  }

  .order-export-btn:not(:disabled):not(.disabled):active {
    color: var(--darkest-grey-modal);
    background-color: var(--background-color);
    border-color: $foreground-color;
    box-shadow: none;
  }

  .order-export-btn:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
  }

  .ag-react-container {
    width: 100%;
  }

  .tab-button {
    align-items: center;
    background-color: var(--white-color);
    border-color: var(--border-new-color);
    cursor: pointer;
    font-family: "Assistant", sans-serif !important;
    font-size: 12px !important;
    font-weight: 700 !important;
    color: var(--background-color);
    justify-content: center;
    border-radius: 4px !important;
    margin: 4px 10px;
  }

  .cancel-order {
    padding: 0;

    svg {
      fill: red !important;
      font-size: 16px;
      margin-left: -4px;
      margin-top: -4px;
    }
  }

  .selected-footer {
    background-color: var(--foreground-color) !important;
    color: var(--white-color) !important;
    font-weight: 500 !important;
  }

  .account-dropdown-selector{
    width: calc(100%) ;
    margin-top: 8px;
    border-radius: 8px;
    height: 24px;
    background-color: var(--background-color);
    border-color: var(--dark-orange);
    color: #6c757d;
  }
  .fetch-order-btn{
    background-color: var(--foreground-color) !important;
    height: auto;
    width: 100%;
    text-align: center;
    justify-content: center;
    padding: 4px;
    border-color: var(--dark-orange) !important;
    
  }
}

.filter-option {
  background-color: $widgets-area-bg-color;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 45%;
  color: $foreground-color !important;
}


.order-blotter .account-dropdown-selector{
  border: 1px solid var(--dark-orange);
  color: var(--white-color);
  margin: 0;
  height: 28px;
  background-image: url(../../Images/arrow-down.svg);
  background-repeat: no-repeat;
  background-position: calc(100% - 6px) 50%;
  background-size: 11px;
  font-size: 12px;
  padding: 0 10px;
}

input::-webkit-calendar-picker-indicator {
  opacity: 0;
}

.order-blotter .fetch-order-btn{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  font-size: 12px;
  padding: 4px 15px;

}
.order-blotter .fetch-order-btn:focus{
  background-color: #bf5a05 !important;
  }

.order-blotter .orderList{
  padding: 0 5px;
  max-height: 50px !important;
}

.ag-theme-alpine .ag-horizontal-left-spacer:not(.ag-scroller-corner){
  border:none !important;
}

.order-blotter .tab-button{
  align-items: center;
  height: 30px;
}

.smallfont{
  font-size: 14px !important;
}

@media only screen and (min-width: 500px) {
  .smallfont{
    font-size: 12px !important;
  }
  .order-blotter .fetch-order-btn{
    font-size: 12px !important;
  }
}

.order-blotter .fetch-order-btn{
  font-family: "Poppins", sans-serif !important;
  font-size: 12px !important;
  line-height: 24px !important;
  font-weight: 600 !important;
  width: 100% !important;
  padding: 5px 30px !important;
  border: 1px solid var(--border-new-color) !important;
  color: var(--white-color) !important;
  white-space: nowrap !important;
  &.btn-red{
    background-color: var(--grid-danger-color) !important;
    border-color: var(--grid-danger-color) !important;
  }
  &.btn-blue{
    background-color: var(--foreground-color) !important;
    border-color: var(--foreground-color) !important;
  }
  &.btn-pink{
    background-color: palevioletred !important;
    border-color: palevioletred !important;
  }
  &.btn-green{
    background-color: var(--grid-success-color) !important;
    border-color: var(--grid-success-color) !important;
  }
}

.css-1s2u09g-control, .css-1pahdxg-control{
  border: 1px solid var(--dark-orange) !important;
    color: var(--white-color) !important;
    margin: 0 !important;
    height: 28px !important;
    background-color: var(--background-color) !important;
    font-size: 14px !important;
    padding: 0 10px !important;
    box-shadow: none !important;
    &:focus, &:hover{
      border: 1px solid var(--dark-orange) !important;
      color: var(--white-color) !important;
      margin: 0 !important;
      background-color: var(--background-color) !important;
    }
}

.css-319lph-ValueContainer{
  padding: 0 !important;
}

.css-6j8wv5-Input, .css-qbdosj-Input, .css-qc6sy-singleValue{
  font-family: "Poppins", sans-serif !important;
  font-size: 12px !important;
  line-height: 14px !important;
  color: var(--white-color) !important;
  &::placeholder{
   color: var(--order-select-text-color) !important;
  }
}

.css-1dimb5e-singleValue{  
  &:focus{
  color: var(--order-select-text-color) !important;
 }
  color: var(--white-color) !important;
}

.orderList {
  .form-label{
    color: var(--white-color) !important;
  }
  .form-check-label{
    font-family: "Poppins", sans-serif !important;
    font-size: 14px !important;
    line-height: 28px !important;
    color: var(--white-color) !important;
  }
  .form-check input{
    cursor: pointer !important;
    width: 15px !important;
    height: 15px !important;
    align-items: center !important;
  }
  .form-check input:checked ~ .form-check-label{
    color: var(--foreground-color) !important;
    font-weight: 600 !important;
  }
}

.mw-custom-100{
  max-width: 100% !important;
}
@import "../../variables.scss";

.theme-new-bg{
  background-color: var(--widgets-area-bg-color);
  width: 100%;
}

.main-view {
  box-sizing: border-box;
  height: 100%;
  padding: 3px;
  width: 100%;
  background-color: var(--widgets-area-bg-color);

  .workspaces-list {
    padding: 5px 5px !important;
    background-color: var(--workspace-main-bg-color);
    border: 1px solid var(--border-new-color) !important;
    margin: 5px 1px 10px 0px !important;
    border-radius: 4px !important;
    min-height: initial !important;
  }

  .widgets-area {
    height: 100%;
    margin-top: 5px;
    overflow-x: hidden;
    display: -webkit-inline-box !important;
    position: relative;
    &.widgets-scroll {
      overflow-x: hidden !important;
      overflow-y: scroll !important;
    }
    &::-webkit-scrollbar {
      display: none !important;
    }

    .empty-workspace-message {
      font-size: 16px;
      width: 100%;
    }
  }

  .workspace-tab-container {
    .workspace-tab {
      padding: 3px 0px 3px 8px !important;
      margin: 4px 10px !important;
      gap: 10px !important;
      border-radius: 4px;
      color: var(--white-color) !important;
      background-color: var(--market-bg-color) !important;
      font-family: 'Assistant', sans-serif !important;
      font-size: 14px !important;
      font-style: normal !important;
      font-weight: 600 !important;
      line-height: 16px !important;

      &.selected {
        border-color: var(--foreground-color) !important;
        background-color: var(--foreground-color) !important;
        color: var(--white-color) !important;
      }

      .workspace-menu-dropdown {
        button {
          color: #f7f7f7 !important;
          padding: 0px 4px !important;
        }
      }
    }

    .workspace-menu-dropdown {
      display: inline-block;
      padding: 0 3px;
      visibility: hidden;

      button {
        color: var(--white-color);
      }
    }

    &:hover {
      .workspace-tab {
        color: var(--white-color);
      }

      .workspace-menu-dropdown {
        visibility: visible;
      }
    }
  }

  .customBtnAdd {
    background-color: transparent;
    border: none;
    border-radius: 0;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 0px 10px !important;

    .icon-add-workspace {
      background-color: var(--foreground-color) !important;
      border-radius: 4px !important;
      padding: 10px !important;
      color: var(--market-bg-color) !important;
      width: 33px !important;
      height: 33px !important;
    }
  }

  .customBtnRemove {
    display: none;
  }

  .btn:focus,
  .btn:active {
    outline: none !important;
    box-shadow: none !important;
  }

  .workspace-option {
    color: var(--text-color);
    font-size: 14px;
    width: 129px;
    height: 62px;
    background-color: $background-color;
    border: 1px solid var(--white-color);
    z-index: 1001;

    div {
      padding: 5px 20px;
    }

    div:hover {
      background-color: #e9ecef;
      color: black;
      cursor: pointer;
    }
  }

  #closeBtnDropdown {
    text-align: right;
    padding-right: 10px;
  }

  #closeBtnDropdown:hover {
    color: red;
    cursor: pointer;
  }
}

.text-color {
  color: var(--foreground-color);
}

.login-modal {
  .form-label,
  .form-check-label {
    color: black;
  }

  .form-control {
    height: 46px;
    background-color: inherit;
    border-radius: 4px;
    color: #495057;
    font-size: 20px;

    &focus {
      box-shadow: none;
    }
  }

  .btn-login {
    background-color: var(--foreground-color);
    margin: 0 70px;
    border-color: var(--foreground-color);
  }

  .btn-login:hover,
  .btn-login:active,
  .btn-login:focus {
    background-color: var(--foreground-color) !important;
    border-color: var(--foreground-color) !important;
    box-shadow: none !important;
  }
}

#root {
  .add-widget-container {
    width: 90px;
  }

  .widget-btn {
    background-color: transparent !important;
    border: none !important;
    color: var(--foreground-color) !important;
    font-size: 14px;
    height: 36px;
    padding-left: 1em;
  }

  .ht-dropdown-menu,
  .workspace-menu-dropdown {
    min-width: auto;
    z-index: 2000;

    .dropdown-menu {
      background-color: var(--widgets-area-bg-color) !important;
      min-width: auto;
    }

    a {
      color: var(--white-shade-anchor-color);
      font-size: 12px;
      padding-left: 10px;
      padding-right: 10px;

      &:hover {
        color: var(--white-shade-anchor-color);
        background-color: var(--foreground-color);
      }
    }

    hr {
      border-color: var(--white-color);
      margin: 0;
    }
  }
}

.confirmation-modal {
  .modal-header {
    background-color: var(--toolbar-bg-color);
    color: var(--white-color);
    border: none;
  }

  .modal-body {
    background-color: var(--toolbar-bg-color);
    color: var(--white-color);
  }

  .modal-footer {
    background-color: var(--toolbar-bg-color);
    border: none;

    button {
      background-color: var(--toolbar-bg-color);
      color: var(--foreground-color) !important;
      border: none;

      &:hover,
      &:active,
      &:visited {
        background-color: var(--toolbar-bg-color) !important;
        color: var(--foreground-color) !important;
        border: none;
        box-shadow: none !important;
      }
    }
  }

  .modal-dialog {
    display: flex !important;
    justify-content: center !important;
  }

  .modal-content {
    background: var(--toolbar-bg-color) !important;
    background-color: var(--toolbar-bg-color) !important;
    border: 2px solid var(--border-new-color);
    border-left: 1px solid var(--border-new-color) !important;
    border-right: 1px solid var(--border-new-color) !important;
    max-width: fit-content !important;
  }

  .sell-order,
  .cancel-sell-order {
    color: #c43019;
  }

  .buy-order,
  .cancel-buy-order {
    color: #217fe2;
  }

  .short-order {
    color: #f322c9;
  }
}

.dropdown-menu-right {
  left: auto !important;
  right: 0 !important;
  min-width: 112px !important;

  transform: translate(0, 38px) !important;
}

.dropdown-menu-right a:focus {
  color: var(--white-shade-anchor-color);
  background-color: var(--foreground-color);
}

.shell-view {
  position: relative !important;
}

.e-contextmenu-wrapper ul.e-contextmenu {
  padding: 0px !important;

  .e-menu-item {
    color: var(--white-color) !important;
    background-color: #292726 !important;
  }
}

.e-contextmenu-wrapper ul.e-contextmenu .e-menu-item {
  &:hover {
    background-color: var(--foreground-color) !important;
  }
}

.main-view.side-collap .shell-view.shell-collaps .flex-view.side-width {
  width: 865px !important;
}

div.trans-shell > div > .shell-view.mini-wiget {
  position: fixed !important;
  top: 55% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

div.trans-shell > div > .shell-view.max-wiget {
  position: fixed !important;
  top: 15% !important;
  left: 14.7% !important;
  max-height: 780px !important;
  max-width: 85.38% !important;
}

// Custom Select dropdown
.css-13cymwt-control {
  background-color: var(--ag-grid-header-color) !important;
  border: 1px solid var(--border-new-color) !important;
  border-radius: 6px !important;
  box-shadow: none !important;
  outline: none !important;
  cursor: pointer !important;
}

.css-1u9des2-indicatorSeparator{
  display: none !important;
}

.css-t3ipsp-control:hover,
.css-t3ipsp-control:focus,
.css-t3ipsp-control:active {
  border-color: var(--foreground-color) !important;
  background-color: var(--toolbar-bg-color) !important;
}

.css-1nmdiq5-menu > div {
  margin: 0px !important;
  padding: 0px !important;
  background-color: var(--ag-grid-header-color) !important;
}

.css-1nmdiq5-menu {
  padding: 0px !important;
  width: max-content !important;
  overflow-wrap: normal !important;
  flex-wrap: nowrap !important;
}

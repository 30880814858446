:root {
  --background-color: #111111;
  --background-color-2: #1b2028;
  --foreground-color: #f7931a;
  --text-color: #d8dee6;
  --theme-color-2: #ee6f04;
  --grid-theme-color: #f6f600;
  --dark-orange: #fd7e14;
  --orange-btn-color: #f68624;
  --market-bg-color: #31353f;
  --workspace-main-bg-color: #181A25;
  --ag-grid-header-color: #191F2A;
  --toolbar-bg-color: #14181f;

  --widget-tabs-bg-color: #222222;
  --widgets-area-bg-color: #131419;
  --box-text-color: #1B1D24;

  --row-header-background: #222222;
  --row-hover-background: #080707;
  --table-body-background: #030303;

  --long-position-color: #209920;
  --short-position-color: #f322c9;
  --short-bg-new-color: #71A8FE;
  --header-bg-color: #201f1d;
  --row-bg-color: #333333;
  --row-bg-hover-color: #1f1f1f;
  --border-row-color: #474747;

  --white-color: #ffffff;
  --white-shade-anchor-color: #eaeaea;
  --dark-grey: #ccc;
  --medium-grey: #858585;
  --dark-medium-grey: #898989;
  --scroll-track-color: #303241;

  --success-color: #029c3a;
  --danger-color: #e32430;

  --darkest-blue-color: #323232;
  --darkest-grey-modal: #9e9e9e;
  --dark-input-color: #999999;
  --medium-dark-black: #555555;
  --pagination-bg-color: #454545;
  --grid-data-grey: #C5CCD4;
  --grid-success-color: #00A162;
  --grid-danger-color: #ff3c3c;
  --order-select-text-color: #828D99;

  --border-new-color: #30333a;
}

$background-color: var(--background-color);
$foreground-color: var(--foreground-color);
$text-color: var(--text-color);
$theme-color-2: var(--theme-color-2);
$grid-theme-color: var(--grid-theme-color);
$dark-orange: var(--dark-orange);
$orange-btn-color: var(--orange-btn-color);

$widget-tabs-bg-color: var(--widget-tabs-bg-color);
$widgets-area-bg-color: var(--widgets-area-bg-color);

$row-header-background: var(--row-header-background);
$row-hover-background: var(--row-hover-background);
$table-body-background: var(--table-body-background);

$long-position-color: var(--long-position-color);
$short-position-color: var(--short-position-color);
$header-bg-color: var(--header-bg-color);
$row-bg-color: var(--row-bg-color);
$row-bg-hover-color: var(--row-bg-hover-color);
$border-row-color: var(--border-row-color);

$white-color: var(--white-color);
$white-shade-anchor-color: var(--white-shade-anchor-color);
$dark-grey: var(--dark-grey);
$medium-grey: var(--medium-grey);
$dark-medium-grey: var(--dark-medium-grey);

$success-color: var(--success-color);
$danger-color: var(--danger-color);

$darkest-blue-color: var(--darkest-blue-color);
$darkest-grey-modal: var(--darkest-grey-modal);
$dark-input-color: var(--dark-input-color);
$medium-dark-black: var(--medium-dark-black);
$pagination-bg-color: var(--pagination-bg-color);
$border-new-color: var(--border-new-color);
$market-bg-color: var(--market-bg-color);

@import "../../variables.scss";

.header {
  font-size: 12px;
  background-color: $widgets-area-bg-color;
  padding: 0 15px;

  .logo-container {
    display: contents;
    cursor: pointer;
    padding: 6px;
  }

  .advertisement {
    border: 2px solid $widgets-area-bg-color;
    font-size: 12px;
    max-width: 240px;
    margin: 0 16px;
    background: linear-gradient(90deg,
        rgb(38, 117, 32) 10%,
        rgba(44, 160, 36, 1) 51%,
        rgb(72, 209, 62) 85%);
  }

  .header-data {
    align-self: center;
    line-height: 2;
    max-width: 100px;
    padding: 2px;
    text-align: center;
    width: 100px;

    &.positions {
      width: 80px;
    }
  }

  .header-border {
    align-self: center;
    border-left: 1px solid $foreground-color;
    height: 80%;
    margin: 0 7px;
  }

  .header-market-data {
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 600px;
  }

  .clock-section {
    height: 75px !important;
    margin: 4.3px 10px 0px !important;

    .time-clock {
      font-family: "Poppins", sans-serif !important;
      font-size: 14px !important;
      display: flex !important;
      align-items: center !important;
      padding: 10px 0px !important;
      font-weight: 500 !important;
      line-height: 18px !important;
      color: var(--white-color) !important;
      text-transform: uppercase !important;
      gap: 10px !important;
      background-color: var(--ag-grid-header-color) !important;
      border-radius: 8px !important;
      width: 120px !important;
      justify-content: center !important;

      #clock_timer {
        font-family: 'Archivo', sans-serif !important;
        font-size: 14px !important;
        line-height: 18px !important;
        color: #E7C181 !important;
      }
    }
  }

  .maximize-section {
    align-items: center;
    margin-left: 15px;
  }

  .profile-section {
    flex: 1 !important;

    .user-profile {
      width: 40px;
      border-radius: 10px;
      overflow: hidden;
      margin: 0px 10px !important;

      img {
        width: 30px;
        height: 30px;
      }
    }
  }

  .btn-user-dropdown {
    background-color: $foreground-color !important;
    font-size: inherit !important;
    color: var(--widgets-area-bg-color) !important;
    padding: 2px !important;
    justify-content: center !important;
    text-align: center !important;
    font-size: 10px !important;
  }

  .btn:focus,
  .btn:active {
    outline: none !important;
    box-shadow: none !important;
  }

  .widgetBtn {
    background-color: transparent !important;
    border: none !important;
    display: flex !important;
    align-items: center !important;
  }

  .user-options-dropdown {
    z-index: 1001;
  }

  #viewFullScreenBtn:hover {
    color: $foreground-color;
    transition: 0.4s ease-in-out;
    font-size: 2.5em;
  }

  .list-Dropdown-menu {
    background-color: var(--header-bg-color);
    border: var(--row-bg-color) 1px solid;
    color: var(--row-bg-color);
    min-width: auto;
    z-index: 2000;

    button {
      font-size: 14px;
      padding: 4px 14px;

      &:hover {
        background-color: var(--row-bg-color);
        color: var(--white-color);
      }
    }

    .dropdown-divider {
      border-color: var(--row-bg-color);
    }
  }
}

.change-password-modal {

  .form-label,
  .form-check-label {
    color: black;
  }

  .form-control {
    height: 46px;
    background-color: inherit;
    border-radius: 4px;
    color: #495057;
    font-size: 20px;

    &focus {
      box-shadow: none;
    }
  }

  .btn-change-password {
    background-color: $foreground-color;
    border-color: $foreground-color;
  }

  .btn-change-password-close {
    background-color: var(--danger-color);
    border-color: var(--danger-color);
  }

  .btn-change-password:hover,
  .btn-change-password:active,
  .btn-change-password:focus {
    background-color: $foreground-color !important;
    border-color: $foreground-color !important;
    box-shadow: none !important;
  }

  .btn-change-password-close:hover,
  .btn-change-password-close:active,
  .btn-change-password-close:focus {
    background-color: var(--danger-color) !important;
    border-color: var(--danger-color) !important;
    box-shadow: none !important;
  }
}

#animationText {
  color: #ededed;
  text-align: center;
  animation: reveal 6s infinite ease-in-out;
  border: white;
  font-size: 15px;
  border-color: #c4c4c4;
}

@keyframes reveal {
  0% {
    opacity: 0;
    width: 355px;
  }

  20% {
    opacity: 1;
    width: 355px;
  }

  30% {
    width: 355px;
  }

  80% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    width: 355px;
  }
}

.header {
  .profile-section {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
}

.user-options-dropdown {
  button {
    img {
      margin-left: 10px;
      height: 15px;
    }
  }

  .dropdown-menu {
    border: none !important;
    top: 20px !important;
    padding: 0;
    margin: 0;
    font-family: "Poppins", sans-serif !important;
    font-size: 12px !important;
    line-height: 24px !important;
    font-style: normal !important;
    color: var(--white-color) !important;
    background-color: var(--widgets-area-bg-color) !important;
    box-shadow: 0 -1px 20px var(--border-new-color);

    & .dropdown-item {

      &:focus,
      &:hover {
        background-color: var(--border-new-color) !important;
        box-shadow: none !important;
      }
    }
  }
}

@media (max-width: 1199px) {
  .header .logo-container img {
    height: 26px;
  }

  .header .profile-section .user-profile {
    width: 40px;
    margin-right: 5px;
  }

  #clock_timer {
    font-size: 14px;
  }

  .header .clock-section .time-clock {
    width: 120px;
  }

  .header .maximize-section {
    margin: 0;
  }

  header .logo-holder {
    flex: 0 0 20% !important;
  }

  header.header-market-holder {
    flex: 0 0 50% !important;
  }

  header .header-top-right {
    flex: 0 0 30% !important;
  }
}

.header-market-holder {
  align-items: center;

  span.sc-dlfnuX.dzwAGb {
    background-color: var(--foreground-color) !important;
    font-size: 20px !important;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  input[type="text"] {
    position: relative;
    padding-left: 10px !important;
    width: -webkit-fill-available !important;
    background-color: transparent !important;
    font-family: "Assistant", sans-serif !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    border: .5px solid var(--border-new-color) !important;
    border-right: 0px !important;
    border-radius: 0px !important;
    color: var(--white-color) !important;

    &::placeholder {
      text-align: left;
      font-size: large;
    }

    &:focus,
    &:active {
      border: .5px solid var(--dark-medium-grey) !important;
    }
  }

  .jWJsas>ul>li {
    background-color: var(--widgets-area-bg-color) 000;
    border: 1px solid #221f50;
    font-size: 16px;

    &:hover {
      background-color: var(--background-color);
      color: var(--foreground-color);
    }
  }

  svg.searchIcon {
    margin-top: 1px !important;
    color: var(--white-color) !important;
    align-items: center;
    background-color: var(--foreground-color);
    display: inline-flex;
    padding: 0px 10px;
    align-items: flex-start;
    width: 34px;
    height: 34px;
    border-radius: 0px 4px 4px 0px;
    background: #f7931a;
  }
}

div.searchbar {
  width: 480px !important;
  min-height: 35px !important;
  max-height: 35px !important;

  &>div {
    width: -webkit-fill-available !important;
  }
}

.jWJsas {
  position: absolute;
  width: 100%;
  margin-top: 40px !important;
  color: var(--white-color);
  border-radius: 10px !important;
  width: -webkit-fill-available !important;
  z-index: 9999;

  ul {
    max-width: 100%;
  }
}

div.dropdown.module {
  width: auto !important;

  &>button {
    width: 130px !important;
    background-color: transparent !important;
    border: 1px solid var(--border-new-color) !important;
    border-radius: 4px 0px 0px 4px !important;
    border-right: none !important;
    padding-right: .2rem !important;

    &::after {
      position: absolute;
      right: 12px;
      top: 18px;
    }
  }

  &.show {
    &>button {
      outline: 0 !important;
      box-shadow: none !important;
      background-color: var(--border-new-color) !important;
      border: 1px solid var(--border-new-color) !important;
    }
  }

  .dropdown-menu {
    z-index: 99999 !important;
    width: -webkit-fill-available !important;
    background-color: var(--widgets-area-bg-color) !important;
  }

  span {
    display: flex;
    padding-right: 10px;
    width: 50px !important;
    font-family: "Assistant", sans-serif !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;

    &::after {
      position: absolute;
      right: 12px;
      top: 18px;
    }
  }

  .dropdown-item {
    font-family: "Poppins", sans-serif !important;
    font-size: 14px !important;
    font-style: normal !important;

    &:focus,
    &:hover {
      background-color: var(--border-new-color) !important;
      color: var(--white-color) !important;
    }
  }
}

.downarrow-img {
  margin-top: 10px !important;
}

.notification-section {
  display: inline-flex !important;
  padding: 10px 12px !important;
  align-items: center !important;
  gap: 5px !important;
  background-color: var(--background-color-2, #1b2028) !important;
  border-radius: 8px !important;
  min-width: max-content !important;
}

.profile-name {
  color: var(--white-color) !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;

  &.text-truncate {
    max-width: 100px !important;
  }
}

.max-width-100 {
  max-width: 100% !important;
}


.custom-search-user-table.header-search {
  .css-b62m3t-container {
    height: 36px !important;
    border: none !important;
    border-radius: 0px !important;
    box-shadow: none !important;

    .css-13cymwt-control {
      background-color: var(--background-color) !important;
      min-height: 36px !important;
    }

    .css-t3ipsp-control {
      box-shadow: none !important;
      border: 0.2px solid var(--foreground-color) !important;
      background-color: var(--background-color) !important;
      border-radius: 5px !important;
    }

    .css-t3ipsp-control,
    .css-13cymwt-control {
      max-height: 36px !important;
      border-radius: 0px !important;
    }

    .css-1jqq78o-placeholder {
      padding-left: 6px !important;
    }

    .css-1xc3v61-indicatorContainer {
      padding: 4px 8px !important;
    }
    
    .css-1hb7zxy-IndicatorsContainer{
      height: 30px !important;
    }
  }

  .css-1fdsijx-ValueContainer,
  .css-qbdosj-Input,
  .css-qbdosj-Input>input,
  .css-166bipr-Input,
  .css-166bipr-Input>input {
    color: var(--white-color) !important;
    font-size: 15px !important;
    text-wrap: nowrap !important;
    border: none !important;
    width: 300px !important;
    padding-left: 3px !important;
  }
}

.css-1nmdiq5-menu,
.css-1nmdiq5-menu>div {
  width: 100% !important;
}

.css-1nmdiq5-menu {
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  max-height: 300px !important;
  position: absolute !important;
}

.custom-search-user-table.header-search .css-b62m3t-container, .custom-search-user-table.header-search .css-b62m3t-container .css-t3ipsp-control, .custom-search-user-table.header-search .css-b62m3t-container .css-13cymwt-control{
  min-height: 36px !important;
  max-height: 36px !important;
}

.auto-search-custom {
  .e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
  .e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,
  .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,
  .e-float-input.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,
  .e-float-input.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap {
    border: none !important;
    height: 35px !important;
    background-color: transparent !important;
  }
  .e-input-group:not(.e-float-icon-left):not(.e-float-input)::before, .e-input-group:not(.e-float-icon-left):not(.e-float-input)::after, .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before, .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after, .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before, .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after, .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before, .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after{
    background-color: transparent !important;
  }
  .e-input-group input.e-input, .e-input-group.e-control-wrapper input.e-input{
    font-size: 20px !important;
  }
  .e-input-group:not(.e-success):not(.e-warning):not(.e-error) input.e-input:focus, .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error) input.e-input:focus, .e-input-group:not(.e-success):not(.e-warning):not(.e-error).e-input-focus input.e-input, .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error).e-input-focus input.e-input, .e-input-group:not(.e-success):not(.e-warning):not(.e-error) textarea.e-input:focus, .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error) textarea.e-input:focus{
    border: 0.5px solid var(--border-new-color) !important;
  }
}
.form-label {
  color: var(--white-color);
  margin-bottom: 0rem;
  font-family: "Poppins", sans-serif !important;
  font-size: 12px !important;
  line-height: 14px !important;
}

.form-control.fc-field {
  width: 60%;
  border: 1px solid rgba(255, 255, 255, 0.658) !important;
  font-family: "Inter", sans-serif !important;
  border-radius: 0px !important;
  /* font-weight: bold; */
  padding: 0.3rem 0.95rem !important;
  color: var(--white-color) !important;
  display: flex !important;
  border-radius: 0.175rem !important;
  align-items: center !important;
  border-radius: 0.175rem !important;
  height: 35px !important;
  font-size: 12px !important;
  line-height: 14px !important;

  &:focus {
    border: 1px solid var(--foreground-color) !important;
    color: var(--foreground-color) !important;
    cursor: pointer !important;
  }
}

.form-control:disabled,
.form-control[readonly] {
  background-color: var(--toolbar-bg-color) !important;
  color: var(--theme-color-2) !important;
  opacity: .65 !important;
  border-color: #a3a3a32c !important;
  border-radius: 2px !important;
}

.btn-close:focus {
  box-shadow: none !important;
}

.btn-form {
  background-color: var(--theme-color-2) !important;
  border-color: var(--theme-color-2) !important;
  color: var(--white-color) !important;
  font-weight: 500;
  padding-top: 1px;
  padding-bottom: 1px;
  margin: 10px;
  border-radius: 2px !important;
  pointer-events: visible !important;

  &.btn-drag-drop {
    background-color: var(--text-color) !important;
    border: 1px solid var(--text-color) !important;
    color: var(--toolbar-bg-color) !important;
    width: 50px !important;
    padding: 12px !important;
  }

  &.disable,
  &:disabled {
    background-color: grey !important;
    color: var(--border-new-color) !important;
    border-color: grey !important;
    cursor: not-allowed;
  }
}

.btn-form:hover {
  color: var(--white-color) !important;
  border-color: var(--foreground-color) !important;
  background-color: var(--foreground-color) !important;
}

.btn-form:focus {
  box-shadow: none !important;
  color: var(--white-color) !important;
  border-color: var(--dark-orange) !important;
  background-color: var(--dark-orange) !important;
}

/* .btn-form:active{
    color: var(--widgets-area-bg-color) !important;
} */

.form-select {
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PHN2ZyB3aWR0aD0iMTFweCIgaGVpZ2h0PSIxMHB4IiB2aWV3Qm94PSIwIDAgMTEgMTAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiPiAgICA8ZyBpZD0iV2VsY29tZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc2tldGNoOnR5cGU9Ik1TUGFnZSI+ICAgICAgICA8ZyBpZD0iRGVza3RvcC1IRCIgc2tldGNoOnR5cGU9Ik1TQXJ0Ym9hcmRHcm91cCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTUyMy4wMDAwMDAsIC0zMTkuMDAwMDAwKSIgZmlsbD0iIzQyNDI0MiI+ICAgICAgICAgICAgPGcgaWQ9ImZhLXBsYXktMiIgc2tldGNoOnR5cGU9Ik1TTGF5ZXJHcm91cCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNTI4LjUwMDAwMCwgMzI0LjAwMDAwMCkgcm90YXRlKDkwLjAwMDAwMCkgdHJhbnNsYXRlKC01MjguNTAwMDAwLCAtMzI0LjAwMDAwMCkgdHJhbnNsYXRlKDUyNC4wMDAwMDAsIDMxOS4wMDAwMDApIj4gICAgICAgICAgICAgICAgPHBhdGggZD0iTS0wLjA2Nzc2NDcwNTksMTAuMDYwNTI2MyBDMC4wMDM3MDU4ODIzNSwxMC4wOTU3ODk1IDAuMDk5LDEwLjA5NTc4OTUgMC4xOTM3NjQ3MDYsMTAuMDM2ODQyMSBMOC45OTg5NDExOCw1LjE2OTQ3MzY4IEM5LjEwNTg4MjM1LDUuMTEwNTI2MzIgOS4xNTM1Mjk0MSw1LjA1MTU3ODk1IDkuMTUzNTI5NDEsNC45Njg5NDczNyBDOS4xNTM1Mjk0MSw0Ljg4NTc4OTQ3IDkuMTA1ODgyMzUsNC44MTUyNjMxNiA4Ljk5ODk0MTE4LDQuNzY3ODk0NzQgTDAuMTkzNzY0NzA2LC0wLjA5OTQ3MzY4NDIgQzAuMDk5LC0wLjE1ODQyMTA1MyAwLjAwMzcwNTg4MjM1LC0wLjE3IC0wLjA2Nzc2NDcwNTksLTAuMTIzMTU3ODk1IEMtMC4xMzg3MDU4ODIsLTAuMDc1Nzg5NDczNyAtMC4xNzQ3MDU4ODIsLTAuMDA0NzM2ODQyMTEgLTAuMTc0NzA1ODgyLDAuMTEzNjg0MjExIEwtMC4xNzQ3MDU4ODIsOS44MjQyMTA1MyBDLTAuMTc0NzA1ODgyLDkuOTMwNTI2MzIgLTAuMTM4NzA1ODgyLDEwLjAxMzE1NzkgLTAuMDY3NzY0NzA1OSwxMC4wNjA1MjYzIiBpZD0iRmlsbC0xIiBza2V0Y2g6dHlwZT0iTVNTaGFwZUdyb3VwIj48L3BhdGg+ICAgICAgICAgICAgPC9nPiAgICAgICAgPC9nPiAgICA8L2c+PC9zdmc+) no-repeat right 7px center;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 850px !important;
    margin-right: auto;
    margin-left: auto;
  }
}

.form-control.fc-field {
  box-shadow: none !important;
  outline: 0 !important;
  width: 100% !important;
}

.form-select {
  box-shadow: none !important;
  border: 1px solid rgba(255, 255, 255, 0.658) !important;
  font-family: "Inter", sans-serif !important;

  &.focus {
    border: 1px solid var(--foreground-color) !important;
    color: var(--white-color) !important;
  }
}

.btn-close:focus {
  box-shadow: none !important;
  opacity: 1 !important;
}

.btn-close {
  opacity: 1 !important;
}

.field span {
  font-size: 25px;
  margin-left: 5px;
}

textarea.form-control.fc-field {
  min-height: calc(1.5em + 0.75rem + 2px);
}

.form-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  font-family: "Inter", sans-serif !important;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='White' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 2px;
  border: 1px solid #dfdfdf;
  border-radius: 0.175rem !important;

  &:focus {
    background-image: url("data:image/svg+xml;utf8,<svg fill='Tomato' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  }
}

.nav-tabs {
  border-bottom: 0.51px solid #fff !important;
  cursor: pointer !important;

  .nav-link {
    color: var(--white-color) !important;
    background-color: transparent !important;
    border-color: transparent !important;
    font-size: 14px !important;
    font-family: 'Inter', sans-serif !important;
    line-height: 18px !important;

    &:hover {
      background-color: var(--foreground-color) !important;
    }

    &:focus,
    &:active,
    &.active {
      background-color: transparent !important;
      border-color: transparent !important;
      border-bottom: 3px solid var(--foreground-color) !important;
      border-top: 1px solid transparent !important;
      border-left: 1px solid transparent !important;
      border-right: 1px solid transparent !important;
    }

    &.disabled {
      color: #919191f7 !important;
    }
  }
}

.form-control.form-select {
  border-color: transparent !important;
  border: 1px solid rgba(255, 255, 255, 0.658) !important;
  padding: 7px !important;
  font-family: "Inter", sans-serif !important;
  color: var(--white-color) !important;
  cursor: pointer !important;

  &:focus {
    border: 1px solid var(--foreground-color) !important;
    color: var(--foreground-color) !important;
  }
}

.form-control#Date {
  border-color: transparent !important;
  border: 1px solid rgba(255, 255, 255, 0.658) !important;
  padding: 7px !important;

  &.focus {
    border: 1px solid var(--foreground-color) !important;
  }
}

.nav-tabs:focus .nav-item>button {
  background-color: transparent !important;
  border-color: transparent !important;
  border-bottom: 3px solid var(--foreground-color) !important;
  border-top: 1px solid transparent !important;
  border-left: 1px solid transparent !important;
  border-right: 1px solid transparent !important;
}

span.minusicon {
  padding: 2px;
  background-color: var(--row-bg-hover-color);
  color: var(--medium-grey);
  border: 1px solid var(--white-color);
  height: 28px;
  font-size: 15px;
  display: flex;
  align-items: center;
  border-radius: 0.175rem !important;
}

input[type="checkbox"] {
  background-color: var(--widgets-area-bg-color) !important;
  height: 15px;
  width: 15px;
  cursor: pointer !important;
  filter: invert(7);
  border: 1px solid var(--toolbar-bg-color) !important;
  outline: 0px !important;
}

span.checklable {
  display: inline-block;
  color: var(--white-color) !important;
  font-size: 12px !important;
  padding-left: 5px;

  &.disable {
    color: var(--maket-bg-color) !important;
    opacity: .70 !important;
  }
}

// destiantion
.heading {
  color: var(--white-color);
}

.row1 {
  color: var(--foreground-color);
}

tbody tr th,
td {
  padding: 0.15rem 0.5rem !important;
  color: var(--foreground-color);
}

/* .my-custom-scrollbar {
    position: relative;
    height: 450px;
    overflow: auto;
} */
.table-wrapper-scroll-y {
  display: block;
}

.color {
  color: var(--foreground-color) !important;
}

/* .button{
    text-align: center;
    margin: auto 0;
} */

.btn-close:focus {
  box-shadow: none !important;
  opacity: 1 !important;
}

.btn-close {
  opacity: 1 !important;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 1000px !important;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (max-width: 350px) {
  .ms-1 {
    margin-left: 0rem !important;
    margin-top: 0.5rem;
  }
}

.ms-1 {
  margin-left: 0.25rem;
}

.box {
  height: 450px;
  width: 100%;
  overflow-y: auto;
  background-color: transparent;
}

.box tbody tr th,
td {
  padding: 0.15rem 0.5rem !important;
  color: var(--white-color);
}

table.destable thead tr th {
  font-family: 'Assistant', sans-serif !important;
  font-size: 14px !important;
  padding: 10px !important;
  font-weight: 500 !important;
  border: 1px solid var(--border-new-color) !important;
}

select {

  &.box1,
  &.box2 {
    width: -webkit-fill-available !important;
    height: auto !important;
    background-color: var(--ag-grid-header-color) !important;
    color: var(--white-color) !important;
    height: 390px !important;

    option {

      &:focus,
      &:active,
      &:checked {
        background-color: var(--foreground-color) !important;
        color: var(--widgets-area-bg-color) !important;
      }

      &:hover {
        background-color: #ff8d30 !important;
        color: var(--toolbar-bg-color) !important;
      }
    }
  }
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: var(--white-color) !important;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  // filter: invert(1);
  background: url(./../../Images/calender.png) no-repeat !important;
  width: 24px !important;
  height: 22px !important;
  margin-top: 3px;
  border-width: thin;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.box {
  height: 450px;
  width: -webkit-fill-available !important;
  overflow-y: auto;
  background-color: transparent;
  border: 1px solid var(--border-new-color) !important;
}

.box tbody tr th,
td {
  padding: 0.15rem 0.5rem !important;
  color: var(--white-color);
}

table.destable thead tr th {
  border: 1px solid var(--border-new-color) !important;
}

table.destable {
  tbody tr {
    width: -webkit-fill-available !important;
    cursor: pointer !important;

    &:focus,
    &:active {
      background-color: var(--border-new-color) !important;
      box-shadow: 4px 4px 32px 0px transparent !important;
      color: var(--white-color) !important;
    }

    &:hover {
      background-color: var(--border-new-color) !important;
      box-shadow: 4px 4px 32px 0px transparent !important;
      color: var(--white-color) !important;
    }
  }
}

a.btn-form:disabled {
  background-color: var(--darkest-blue-color) !important;
  color: var(--medium-grey) !important;
  border-color: var(--toolbar-bg-color) !important;
}

.rmsc {
  width: -webkit-fill-available !important;

  .dropdown-heading {
    height: 30px !important;
  }
}

.rmsc .dropdown-container {
  background-color: transparent !important;
  border: 1px solid rgba(255, 255, 255, 0.658) !important;
  height: fit-content !important;
  width: -webkit-fill-available !important;

  & svg.dropdown-search-clear-icon {
    display: none !important;
  }
}

.dropdown-heading-value {
  width: -webkit-fill-available !important;

  span {
    font-size: 14px !important;
    color: var(--white-color) !important;
  }
}

.dropdown-content {
  &>div {
    height: 110px !important;
    overflow-y: scroll !important;
    background-color: var(--toolbar-bg-color) !important;
    border: 1px solid rgba(255, 255, 255, 0.658) !important;
    padding: 0 !important;

    &>div div {
      padding: 0 !important;
      margin: 0 !important;

      span {
        height: fit-content !important;
        font-size: 14px !important;
        color: var(--foreground-color) !important;
      }
    }

    .dropdown-items {
      padding: 0 !important;
      align-items: center !important;
      margin: 0% !important;

      &:focus,
      &:active {
        background-color: transparent !important;
        color: var(--widgets-area-bg-color) !important;
      }

      &:hover {
        background-color: transparent !important;
        color: var(--toolbar-bg-color) !important;
      }
    }
  }
}

select option {
  color: var(--white-color) !important;
  background-color: var(--border-new-color) !important;
  cursor: pointer !important;
  font-family: "Inter", sans-serif !important;
}

.custom-select-form {
  border-color: transparent !important;
  border: 1px solid rgba(255, 255, 255, 0.658) !important;
  font-family: "Inter", sans-serif !important;
  color: var(--white-color) !important;
  cursor: pointer !important;
  padding: 0px !important;
  background-color: transparent !important;
  overflow: hidden !important;
  max-height: 32px !important;
  padding: 0px !important;

  option {
    max-height: 32px !important;
    padding: 7px 4px 5px !important;
    background-color: var(--background-color) !important;
    overflow: hidden scroll !important;
    &::-webkit-scrollbar{
      width: 6px !important;
    }
  }
}

.box1,
.box2 {
  option {
    color: var(--white-color) !important;
    background-color: var(--ag-grid-header-color) !important;
    cursor: pointer !important;
    font-family: "Inter", sans-serif !important;
    font-size: 12px !important;
    line-height: 14px !important;
  }
}

.event-none {
  pointer-events: none !important;
  cursor: not-allowed !important;
}

.drag-and-drop-search-bar {
  &.note-field {
    input[type="text"] {
      height: 40px !important;
    }
  }

  input[type="text"] {
    position: relative;
    padding-left: 10px !important;
    width: -webkit-fill-available !important;
    background-color: transparent !important;
    font-family: "Assistant", sans-serif !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    border: 1px solid var(--border-new-color) !important;
    border-radius: 0px !important;
    color: var(--white-color) !important;
    max-width: -webkit-fill-available !important;

    &::placeholder {
      text-align: left;
      font-size: large;
    }

    &:focus,
    &:active {
      border: 1px solid var(--foreground-color) !important;
    }
  }

  svg.searchIcon {
    font-size: 10px;
    right: 20px;
    color: var(--white-color) !important;
    align-items: center;
    display: inline-flex;
    padding: 0px 5px;
    align-items: flex-start;
    gap: 5px;
    width: 30px;
    height: 30px;
    font-size: 20px !important;
    border-radius: 0px 4px 4px 0px;
    align-items: center;
    background-color: var(--foreground-color) !important;
  }
}

// todo style

.todo-list {
  list-style-type: none;
  padding: 0;
}

.todo-item {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: var(--ag-grid-header-color) !important;
  border: 1px solid var(--border-new-color) !important;
  border-radius: 5px !important;
  animation: bounce 1s both;
  margin: 5px !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 500 !important;
  color: var(--white-color) !important;
  padding: 5px 8px !important;
  min-width: 35% !important;

  .content {
    background-color: var(--ag-grid-header-color) !important;
    margin-bottom: 3px !important;
  }

  .timestamp {
    font-size: 10px !important;
    line-height: 14px !important;
    font-weight: normal !important;
    color: var(--darkest-grey-modal) !important;
    display: flex !important;
    justify-content: end !important;
  }
}

.remove-btn {
  background-color: var(--foreground-color);
  color: var(--white-color);
  border: none;
  padding: 6px;
  cursor: pointer;
}

.btn-clear-link {
  display: flex !important;
  justify-content: end !important;
  align-items: end !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 18px !important;
  line-height: 26px !important;
  cursor: pointer;
  font-weight: 600 !important;
  color: var(--foreground-color) !important;
  width: -webkit-fill-available !important;
  padding: 10px !important;
  text-decoration: underline !important;
}

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    transform: translate3d(0, -10px, 0);
  }

  70% {
    transform: translate3d(0, -5px, 0);
  }

  90% {
    transform: translate3d(0, -2px, 0);
  }
}

.requird-field {
  font-size: 12px !important;
  line-height: 14px !important;
  font-weight: 500 !important;
}

div#root .form-control.custom-select-form{
  padding: 0px !important;
  background-color: var(--background-color) !important;
}
.e-grid th.e-headercell[aria-sort="descending"] .e-headertext,
.e-grid th.e-headercell[aria-sort="ascending"] .e-headertext {
  color: var(--white-color) !important;
}

.e-headercelldiv {
  text-align: left !important;
}

.e-grid .e-rowcell {
  text-align: left !important;
  line-height: 25px !important;
}

.e-filterbar {
  background-color: var(--row-bg-color) !important;
  color: var(--row-bg-hover-color) !important;
}

.e-grid .e-filterbarcell,
.e-grid .e-filterbarcelldisabled {
  background-color: transparent !important;
}

.e-grid .e-gridheader tr th.e-filterbarcell {
  padding-left: 10px !important;
}

.toolbar-search {
  box-sizing: border-box;
  cursor: pointer !important;
  border: 1px solid var(--border-new-color) !important;
  &:not(:first-child){
    margin-top: 20px !important;
  }

  .title {
    margin: 0 auto 0 5px;
    background-color: var(--toolbar-bg-color) !important;
    height: 30px !important;
    color: var(--white-color) !important;
    font-family: "Poppins", sans-serif !important;
    font-size: 12px !important;
    line-height: normal !important;
    width: 100%;
  }
}

.border-widget{
  border: 1px solid var(--border-new-color) !important;
  height: inherit !important;
  .ag-paging-panel.ag-unselectable{
    border-bottom: 1px solid var(--border-new-color) !important;
  }
}

@import "../../../../node_modules/ag-grid-community/src/styles/ag-grid.scss";

// ag-grid css overwrite

.ag-theme-alpine {
  border: none !important;
}

div#root div.hw-grid.ag-theme-alpine .custom-header .sort-icons>span,
.ag-theme-alpine [class^="ag-"],
.ag-theme-alpine [class^="ag-"]:focus,
.ag-theme-alpine [class^="ag-"]:after {
  font-family: "Assistant", sans-serif !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  color: var(--white-color) !important;
  border: none !important;
}

.ag-theme-alpine .ag-icon-menu:before {
  visibility: hidden !important;
}

.ag-theme-alpine .ag-header {
  background-color: var(--ag-grid-header-color) !important;
  height: 28px !important;
  min-height: 28px !important;
  border: none !important;

  .ag-header-cell-label .ag-header-cell-text {
    color: var(--foreground-color) !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    line-height: 28px !important;
  }

  .ag-header-cell-label {
    padding-left: 10px !important;
  }
}

.ag-icon.ag-icon-menu {
  background-image: url("../../../Images/filter-icon.svg") !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  visibility: visible !important;
  cursor: pointer !important;
}

.ag-header-cell-menu-button:not(.ag-header-menu-always-show) {
  opacity: 0.8 !important;
}

.ag-header-cell {
  padding-left: 0px !important;
  border: none !important;
}

.ag-theme-alpine .ag-header-cell-resize::after {
  opacity: 0.3 !important;
  width: 0.5px !important;
  height: 100% !important;
  top: 0px !important;
  background-color: var(--white-color) !important;
}

.ag-icon.ag-icon-asc,
.ag-icon.ag-icon-dsc {
  opacity: 0.3 !important;
}

.ag-theme-alpine .ag-row {
  border: none !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  cursor: pointer !important;

  .ag-cell {
    opacity: 0.92 !important;
    border: none !important;
    display: flex !important;
    align-items: center !important;
    margin: auto 0px !important;
    padding-left: 10px !important;
  }

  &:nth-child(odd) {
    background-color: var(--widgets-area-bg-color) !important;
  }

  &:nth-child(even) {
    background-color: var(--ag-grid-header-color) !important;
  }

  // &:active, &:focus{
  //   background-color: var(--foreground-color) !important;
  // }
}

.ag-cell-focus.ag-cell-inline-editing {
  padding: 0px !important;

  & .ag-input-field-input {
    border-bottom: 1px solid var(--foreground-color) !important;
    border: none !important;
    background-color: var(--background-color-2) !important;
    padding: 2px 10px !important;
    margin: 0px !important;
    box-shadow: none !important;
    color: var(--foreground-color) !important;
    border-radius: 0px !important;
  }
}

// .ag-theme-alpine
//   .ag-ltr
//   .ag-has-focus
//   .ag-cell-focus:not(.ag-cell-range-selected) {
//   border: none !important;
//   border-bottom: 1px solid var(--foreground-color) !important;
//   outline: 0 !important;
//   box-shadow: none !important;
//   border-radius: 0px !important;
// }

.ag-filter-body-wrapper.ag-simple-filter-body-wrapper {
  background-color: var(--background-color-2) !important;
}

.ag-paging-panel,
.ag-theme-alpine .ag-select .ag-picker-field-wrapper,
.ag-input-field-input {
  background-color: var(--widgets-area-bg-color) !important;
}

// Add user and close btn css

.btn-main-bg {
  background-color: var(--toolbar-bg-color) !important;
}

.reload {
  font-family: "Lucida Sans Unicode";
  font-weight: 900;
  font-size: 25px;
}

.add-btn-form {
  display: flex !important;
  background-color: var(--foreground-color) !important;
  align-items: center !important;
  cursor: pointer !important;
  border-radius: 3px !important;
  vertical-align: middle !important;
  height: 30px !important;

  span {
    font-family: "Inter", sans-serif !important;
    border-color: var(--theme-color-2) !important;
    color: #ffffff !important;
    width: max-content !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    padding: 1px 3px !important;
  }

  &.disable,
  &:disabled {
    background-color: var(--darkest-blue-color) !important;
    color: var(--medium-grey) !important;
    border-color: var(--row-bg-hover-color) !important;
    cursor: not-allowed !important;

    &:hover,
    &:focus {
      background-color: var(--darkest-blue-color) !important;
      color: var(--medium-grey) !important;
      border-color: var(--row-bg-hover-color) !important;
      cursor: not-allowed !important;
    }
  }

  &:hover,
  &:focus {
    background-color: var(--workspace-main-bg-color) !important;

    span {
      color: var(--foreground-color) !important;
    }
  }
}

.btn-bg {
  display: flex !important;
  padding: 10px !important;
  align-items: center !important;
  gap: 10px !important;
  border-radius: 10px 10px 0px 0px !important;
  background-color: var(--ag-grid-header-color) !important;

  .name-person {
    color: #9da9ba !important;
    font-family: "Inter", sans-serif !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
  }
}

// grid dropdown

.dropdown-container {
  position: relative;
  display: inline-block;
}

.dropdown-trigger {
  cursor: pointer;
  font-size: 20px;
  padding: 0 10px;
}

.pagination-header {
  position: absolute !important;
  background-color: var(--widgets-area-bg-color) !important;
  bottom: 0px !important;
  height: 30px !important;
  overflow: hidden !important;
  width: 100% !important;

  span {
    font-family: "Inter", sans-serif !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    padding: 0px 12px !important;
    color: var(--white-color) !important;
  }

  select {
    border-radius: 3px !important;
    border: 1px solid var(--border-new-color) !important;
    background: var(--ag-grid-header-color) !important;
    padding: 5px 10px !important;
    font-family: "Inter", sans-serif !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 14px !important;
    color: var(--white-color) !important;

    option {
      border: 1px solid var(--border-new-color) !important;
      background-color: var(--ag-grid-header-color) !important;
      color: var(--white-color) !important;
      cursor: pointer !important;

      &:hover,
      &:active,
      &:focus {
        background-color: var(--theme-color-2) !important;
        color: var(--white-color) !important;
        background: var(--theme-color-2) !important;
      }
    }
  }

  .btn-page-name {
    padding: 5px 10px;
    font-family: 'Inter', sans-serif !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    color: var(--white-color) !important;
    border: 1px solid var(--border-new-color) !important;
    background: var(--ag-grid-header-color) !important;
    box-shadow: none !important;
    margin: 0px 8px !important;
    border-radius: 3px !important;

    &.disable,
    &:disabled {
      background-color: var(--darkest-blue-color) !important;
      color: var(--medium-grey) !important;
      border-color: var(--row-bg-hover-color) !important;
      cursor: not-allowed !important;

      &:hover,
      &:focus {
        background-color: var(--darkest-blue-color) !important;
        color: var(--medium-grey) !important;
        border-color: var(--row-bg-hover-color) !important;
        cursor: not-allowed !important;
      }
    }

    &:hover,
    &:active,
    &.active {
      background-color: var(--foreground-color) !important;
      color: var(--box-text-color) !important;
      border: 1px solid var(--box-text-color) !important;
    }
  }
}

.ag-paging-panel span {
  visibility: hidden !important;
}

.bottom-value {
  .enabled {
    height: 30px !important;
    border-radius: 3px !important;
    background: #00a162 !important;

    & span,
    & label {
      color: #ffffff !important;
      font-family: "Assistant", sans-serif !important;
      font-size: 19px !important;
      font-style: normal !important;
      font-weight: 600 !important;
      line-height: normal !important;
      margin-bottom: 0px !important;
    }
  }

  .disabled {
    height: 30px !important;
    border-radius: 3px !important;
    background: #a71e4e !important;

    & span,
    & label {
      color: #ffffff !important;
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      font-family: "Assistant", sans-serif !important;
      font-size: 19px !important;
      font-style: normal !important;
      font-weight: 600 !important;
      line-height: normal !important;
      margin-bottom: 0px !important;
    }
  }

  .online-person {
    height: 30px !important;
    border-radius: 3px !important;
    background: #fff !important;

    & span,
    & label {
      color: #000 !important;
      font-family: "Assistant", sans-serif !important;
      font-size: 19px !important;
      font-style: normal !important;
      font-weight: 600 !important;
      line-height: normal !important;
      margin-bottom: 0px !important;
    }
  }
}

// Information table top

.info-table-top {
  position: relative !important;
  background-color: var(--toolbar-bg-color) !important;
  justify-content: space-between !important;
  overflow: hidden auto !important;
  min-height: 80px !important;
  width: 100% !important;

  .infobox {
    padding: 8px 10px 8px 10px !important;
    margin: 4px 3px !important;
    border: 2px solid var(--border-new-color) !important;
    background-color: var(--widgets-area-bg-color) !important;
    border-radius: 10px !important;
    min-width: 194px !important;
    max-width: 11% !important;

    .info-title {
      font-family: "Poppins", sans-serif !important;
      color: var(--theme-color-2) !important;
      font-size: 12px !important;
      line-height: 14px !important;
      font-weight: 400 !important;
    }

    .info-value {
      font-family: "Poppins", sans-serif !important;
      letter-spacing: 1px !important;
      color: var(--white-color) !important;
      font-size: 16px !important;
      line-height: 18px !important;
      font-weight: 500 !important;

      &.positive-number {
        color: var(--grid-success-color) !important;
      }

      &.negative-number {
        color: var(--grid-danger-color) !important;
      }

      &.null-number {
        color: var(--white-color) !important;
      }
    }
  }
}

.ag-theme-alpine .ag-paging-panel {
  height: 35px !important;
}

.add-btn-form.not-hover {
  cursor: auto !important;

  span {
    color: var(--box-text-color) !important;
  }

  &:hover,
  &:focus {
    background-color: var(--foreground-color) !important;

    span {
      color: var(--box-text-color) !important;
    }
  }

  &.enabled-box {
    background-color: var(--grid-success-color) !important;

    &:hover,
    &:focus {
      background-color: var(--grid-success-color) !important;
    }
  }

  &.expired-box {
    background-color: var(--danger-color) !important;

    &:hover,
    &:focus {
      background-color: var(--danger-color) !important;
    }
  }

  &.suspended-box {
    background-color: var(--white-color) !important;

    span {
      color: var(--box-text-color) !important;
    }

    &:hover,
    &:focus {
      background-color: var(--white-color) !important;

      span {
        color: var(--box-text-color) !important;
      }
    }
  }
}

// Custom Class in custom field

.ag-cell.ag-cell-not-inline-editing.ag-cell-auto-height.ag-cell-value {
  color: var(--white-color) !important;
  display: flex !important;
  justify-content: start !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  height: auto !important;

  &.custom-status-class {
    background-color: var(--market-bg-color) !important;
    padding: 4px 10px !important;
    width: auto !important;
    margin: auto !important;
    justify-content: center !important;
  }
}

.ag-theme-alpine .ag-row.ag-row-focus {
  background-color: var(--market-bg-color) !important;
  border-color: var(--row-bg-hover-color) !important;

  & .ag-cell.ag-cell-not-inline-editing.ag-cell-auto-height.ag-cell-value {
    color: var(--theme-color-2) !important;
  }
}

.ag-header-cell.ag-focus-managed.ag-header-cell-moving {
  background-color: var(--market-bg-color) !important;
}

// ag-grid-search


.ag-grid-search-bar {
  input[type="text"] {
    position: relative;
    padding-left: 10px !important;
    width: -webkit-fill-available !important;
    background-color: transparent !important;
    font-family: "Assistant", sans-serif !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    border: 1px solid var(--border-new-color) !important;
    border-right: 0px !important;
    border-radius: 0px !important;
    color: var(--white-color) !important;
    min-width: 565px !important;
    max-width: -webkit-fill-available !important;

    &::placeholder {
      text-align: left;
      font-size: large;
    }

    &:focus,
    &:active {
      border: 1px solid var(--foreground-color) !important;
    }
  }

  svg.searchIcon {
    font-size: 10px;
    right: 20px;
    color: var(--white-color) !important;
    align-items: center;
    display: inline-flex;
    padding: 0px 5px;
    align-items: flex-start;
    gap: 5px;
    width: 30px;
    height: 30px;
    font-size: 20px !important;
    border-radius: 0px 4px 4px 0px;
    align-items: center;
    background-color: var(--foreground-color) !important;
  }
}

.ag-cell.ag-cell-not-inline-editing.ag-cell-auto-height.ag-cell-value {

  &.positive-value,
  &.positive-cell,
  &.price-positive {
    color: var(--grid-success-color) !important;
    &.custom-center {
      justify-content: center !important;
    }
  }

  &.negative-value,
  &.negative-cell,
  &.price-negative {
    color: var(--grid-danger-color) !important;
    &.custom-center {
      justify-content: center !important;
    }
  }

  &.price-cancel,
  &.null-value {
    color: var(--white-color) !important;
    &.custom-center {
      justify-content: center !important;
    }
  }

  &.positive-bg {
    background-color: var(--grid-success-color) !important;
    padding: 4px 10px !important;
    width: auto !important;
    margin: auto !important;
    &.custom-center {
      justify-content: center !important;
    }
  }

  &.negative-bg {
    background-color: var(--grid-danger-color) !important;
    padding: 4px 10px !important;
    width: auto !important;
    margin: auto !important;
    &.custom-center {
      justify-content: center !important;
    }
  }

  &.null-bg {
    background-color: var(--white-color) !important;
    padding: 4px 10px !important;
    width: auto !important;
    margin: auto !important;
    color: var(--background-color) !important;
    &.custom-center {
      justify-content: center !important;
    }
  }
}

.ag-filter-apply-panel-button {
  display: flex !important;
  background-color: var(--foreground-color) !important;
  align-items: center !important;
  cursor: pointer !important;
  border-radius: 3px !important;
  vertical-align: middle !important;
  height: 30px !important;

  span {
    font-family: "Inter", sans-serif !important;
    border-color: var(--theme-color-2) !important;
    color: #ffffff !important;
    width: max-content !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    padding: 1px 3px !important;
  }

  &.disable,
  &:disabled {
    background-color: var(--darkest-blue-color) !important;
    color: var(--medium-grey) !important;
    border-color: var(--row-bg-hover-color) !important;
    cursor: not-allowed !important;

    &:hover,
    &:focus {
      background-color: var(--darkest-blue-color) !important;
      color: var(--medium-grey) !important;
      border-color: var(--row-bg-hover-color) !important;
      cursor: not-allowed !important;
    }
  }

  &:hover,
  &:focus {
    background-color: var(--workspace-main-bg-color) !important;

    span {
      color: var(--foreground-color) !important;
    }
  }
}
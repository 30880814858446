@import "../../variables.scss";

.level-II-view {
  box-sizing: border-box;
  font-size: 12px;
  min-height: 405px !important;
  min-width: 450px !important;
  overflow: hidden;
  padding: 5px;

  .symbol,
  .destinations-combo {
    text-transform: uppercase;
  }

  label.form-label,
  .form-control {
    width: calc(100% - 4px);
    margin-bottom: 0;
  }

  .last-value {
    font-size: 16px;
    justify-content: center;
    margin-left: 12px;
    text-align: center;
  }

  .btn {
    flex-grow: 1;
    font-size: 12px;
    width: calc(100% - 35px);
  }

  .row-2 {
    width: 100%;

    .form-group {
      flex-basis: 16.5%;
      flex-grow: 1;
      align-content: flex-start;
      margin-bottom: 0;
      margin-top: 0;
    }
  }

  .form-control,
  .form-control:focus {
    background-color: var(--background-color);
    height: 22px;
    border: 0.5px solid $foreground-color;
    color: var(--darkest-grey-modal);
    border-radius: 5px;
    margin-left: 3px;
    font-size: 12px;
    box-shadow: none;
  }

  .form-control:hover {
    background-color: var(--background-color);
  }

  .form-control::-webkit-outer-spin-button,
  .form-control::-webkit-inner-spin-button {
    -webkit-appearance: none;
    background: var(--white-color) url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAKUlEQVQYlWNgwAT/sYhhKPiPT+F/LJgEsHv37v+EMGkmkuImoh2NoQAANlcun/q4OoYAAAAASUVORK5CYII=) no-repeat center center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 1em;
    border-left: 1px solid #bbb;
    opacity: 0.5;
    /* shows Spin Buttons per default (Chrome >= 39) */
  }

  .form-label {
    line-height: 0px;
    margin-left: 8px;
  }

  .form-group {
    margin-top: 0px;
  }

  #dropdown-basic-button {
    margin-left: 3px;
    border-radius: 5px;
  }

  .btn-time-and-sales,
  .btn-time-and-sales:focus {
    height: 29px;
    background-color: $foreground-color;
    color: var(--white-color);
    margin-left: 2px;
    border: none;
    padding: 1px;
  }

  .btn-time-and-sales:hover,
  .btn-time-and-sales:active {
    background-color: $foreground-color !important;
    color: var(--white-color) !important;
    opacity: 0.7;
    border: none;
  }

  .btn-flat-position,
  .btn-flat-position:focus {
    height: 29px;
    background-color: #50a5ff;
    color: var(--white-color);
    margin-left: 2px;
    border: none;
    padding: 1px;
  }

  .btn-flat-position:hover,
  .btn-flat-position:active {
    background-color: #50a5ff !important;
    color: var(--white-color) !important;
    opacity: 0.7;
    border: none;
  }

  .buy-custom-btn,
  .buy-custom-btn:focus {
    height: 22px;
    background-color: #217fe2;
    color: var(--white-color);
    margin-left: 4px;
    border: none;
    padding: 1px;
  }

  .buy-custom-btn:hover,
  .buy-custom-btn:active {
    background-color: #217fe2 !important;
    color: var(--white-color) !important;
    opacity: 0.7;
    border: none;
  }

  .sell-custom-btn,
  .sell-custom-btn:focus {
    height: 22px;
    background-color: #c43019;
    color: var(--white-color);
    margin-left: 4px;
    border: none;
    padding: 1px;
  }

  .sell-custom-btn:hover,
  .sell-custom-btn:active {
    background-color: #c43019 !important;
    color: var(--white-color) !important;
    opacity: 0.7;
    border: none;
  }

  .cancel-buy-custom-btn,
  .cancel-buy-custom-btn:focus {
    height: 22px;
    background-color: #50a5ff;
    color: var(--white-color);
    margin-left: 4px;
    border: none;
    padding: 1px;
  }

  .cancel-buy-custom-btn:hover,
  .cancel-buy-custom-btn:active {
    background-color: #50a5ff !important;
    color: var(--white-color);
    opacity: 0.7;
    border: none;
  }

  .sell-buy-custom-btn,
  .sell-buy-custom-btn:focus {
    height: 22px;
    background-color: #c43019;
    color: var(--white-color);
    margin-left: 2px;
    border: none;
    padding: 1px;
  }

  .sell-buy-custom-btn:hover,
  .sell-buy-custom-btn:active {
    background-color: #c43019 !important;
    color: var(--white-color) !important;
    opacity: 0.7;
    border: none;
  }

  .short-custom-btn,
  .short-custom-btn:focus {
    height: 22px;
    background-color: #f322c9;
    color: var(--white-color);
    margin-left: 4px;
    border: none;
    padding: 1px;
  }

  .short-custom-btn:hover,
  .short-custom-btn:active {
    background-color: #f322c9 !important;
    color: var(--white-color) !important;
    opacity: 0.7;
    border: none;
  }

  .full-width-form {
    width: 100%;
  }

  div.hw-grid.ag-theme-alpine {
    .ag-cell {
      padding-top: 0 !important;
    }
  }

  .long-position,
  .short-position {
    font-weight: bold;
  }

  .long-position {
    color: #006400;
  }

  .short-position {
    color: $short-position-color;
  }
}

.disabledBtn {
  cursor: not-allowed;
}
@import "../../variables.scss";

$border-color: #666;

div#root {
  div.hw-grid.ag-theme-alpine {
    color: var(--text-color);
    font-size: 12px;
    font-weight: 400;
    height: 100%;
    width: 100%;

    .ag-cell {
      color: var(--grid-theme-color) !important;
      font-family: "Segoe UI", sans-serif !important;
    }

    .ag-body-viewport {
      background-color: var(--widgets-area-bg-color) !important
    }

    .price-positive * {
      color: var( --success-color) !important;
    }

    .price-negative * {
      color: var(--danger-color) !important;
    }

    .ag-header-row,
    .ag-header-row-column {
      line-height: 30px !important;
      font-weight: 100 !important;
    }

    div.ag-row {
      background-color: inherit;
      border: 0;
      font-size: 13px;
      display: flex !important;
      align-items: center !important;

      &:nth-child(odd) {
        background-color: $row-bg-color;
        line-height: 25px !important;
      }
      &:nth-child(even) {
        background-color: $row-bg-hover-color;
        line-height: 25px !important;
      }

      &.level-ii {
        padding-top: 3px;

        &.blue-background {
          * {
            color: black !important;
          }

          &.color-1 {
            background-color: #33a1c9;
          }

          &.color-2 {
            background-color: #38b0de;
          }

          &.color-3 {
            background-color: #00bfff;
          }

          &.color-4 {
            background-color: #87ceeb;
          }

          &.color-5 {
            background-color: #b0e2ff;
          }
        }

        &.blue-foreground {
          &.color-1 * {
            color: #0099cc;
          }

          &.color-2 * {
            color: #00bfff;
          }

          &.color-3 * {
            color: #98f5ff;
          }

          &.color-4 * {
            color: #bfefff;
          }

          &.color-5 * {
            color: #0bb5ff;
          }
        }

        &.yellow-background {
          * {
            color: black !important;
          }

          &.color-1 {
            background-color: #f0e68c;
          }

          &.color-2 {
            background-color: #ffdab9;
          }

          &.color-3 {
            background-color: #ffe4b5;
          }

          &.color-4 {
            background-color: #ffefd5;
          }

          &.color-5 {
            background-color: var(--white-color)fe0;
          }
        }

        &.yellow-foreground {
          &.color-1 * {
            color: #ffd700;
          }

          &.color-2 * {
            color: var(--white-color)f8f;
          }

          &.color-3 * {
            color: #dfff00;
          }

          &.color-4 * {
            color: #fcf55f;
          }

          &.color-5 * {
            color: var(--white-color)8dc;
          }
        }
      }
    }

    .ag-header {
      border-color: $border-color;
      height: 30px !important;

      .ag-header-viewport {
        background-color: $row-header-background;
      }

      .ag-header-row.ag-header-row-column {
        background-color: $row-header-background;

        .ag-header-cell {
          padding: 0 4px;
        }

        .form-control {
          font-size: 1.2em !important;
          border: 0 !important;
        }
      }
    }

    .ag-tabs-header.ag-menu-header,
    .ag-tabs.ag-menu {
      background-color: var(--widgets-area-bg-color) !important;
    }

    .ag-cell {
      border: none;
      font-size: 12px;
      line-height: 20px !important;
      padding-bottom: 2px;
      padding-top: 2px;
    }

    .ag-cell,
    .ag-full-width-row .ag-cell-wrapper.ag-row-group {
      padding-left: 5px;
      padding-right: 0;
    }

    .ag-pinned-right-header,
    .ag-horizontal-right-spacer {
      width: 0 !important;
      min-width: 0 !important;
    }

    .ag-pinned-right-cols-container {
      max-width: 16px !important;
      min-width: 16px !important;
      pointer-events: none;
      cursor: not-allowed;
      position: absolute !important;
      left: 0;
      width: 16px !important;

      * {
        pointer-events: initial;
      }

      .ag-cell {
        border: none;
      }
    }

    .ag-pinned-right-cols-container .ag-row:not(.ag-row-hover),
    .ag-pinned-right-cols-container .ag-row:not(.ag-row-hover) .ag-cell {
      width: 0 !important;
      padding: 0 !important;
    }

    .ag-paging-panel {
      background-color: var(--widgets-area-bg-color) !important;
      border-top-color: $border-color;
      height: 20px;
      padding-bottom: 1px;

      .ag-paging-page-summary-panel {
        margin: 0;
      }
    }

    .custom-header {
      width: 100%;

      .table-dropdown {
        background-color: inherit;
        border: none;
        color: var(--text-color);
        cursor: pointer;
        display: inline;
        font-size: 10px;
        margin-left: 0px;
        margin: 0;
        width: 100%;

        .filter-option {
          background-color: $widgets-area-bg-color;
        }
      }

      .sort-icons {
        display: inline;
        margin-right: 8px;

        > span {
          padding: 0 6px;

          &.no-sort svg {
            font-size: 12px;
          }
        }
      }
    }
  }

  .ag-center-cols-viewport {
    background-color: var(--widgets-area-bg-color) !important;
  }

  .ag-cell-value {
    padding-left: 2px;
  }

  .ag-root-wrapper {
    border: 1px solid #666;
  }

  .ag-icon {
    font-family: agGridAlpine !important;
    font-size: inherit;
  }

  .ag-overlay-loading-wrapper,
  .ag-overlay-loading-center,
  .ag-theme-alpine {
    background-color: var(--widgets-area-bg-color) !important;
    color: $text-color;
    opacity: 1;
  }

  .table-dropdown.form-control {
    color: var(--white-color) !important;
    font-family: "Segoe UI", sans-serif !important;
  }

  .ag-selection-checkbox {
    margin-right: 0;
  }
}

.ag-cell {
  font-size: 14px !important;
  line-height: 25px !important;
  padding: 0rem 0.5rem !important;
  display: flex !important;
  align-items: center !important;

  &.positive-cell span {
    background-color: $long-position-color;
  }

  &.negative-cell span {
    background-color: #c43525;
  }

  &.buy span,
  &.position-positive span {
    background-color: #04478e;
  }

  &.sell span {
    background-color: #c43019;
  }

  &.short span,
  &.position-negative span {
    background-color: $short-position-color;
  }

  .row-delete-button {
    height: 16px;
  }
}

.ag-header-cell-label {
  color: var(--white-color) !important;
  font-family: "Segoe UI", sans-serif !important;
  font-size: 1.2em !important;
  display: flex !important;
  align-items: center !important;
  line-height: 35px !important;
  padding: 0.15rem 0.5rem !important;
}

.ag-cell-label-container {
  line-height: 35px !important;
}

.ag-header {
  height: 20px !important;
  min-height: 25px !important;
  font-weight: 500 !important;
}

.ag-cell {
  height: 20px !important;
}

.ag-theme-alpine .ag-header-cell-resize::after {
  height: 80% !important;
  width: 2px !important;
  top: 2px !important;
  background-color: #e0e0e0 !important;
}

div#root div.hw-grid.ag-theme-alpine .custom-header .sort-icons > span,
.ag-theme-alpine [class^="ag-"],
.ag-theme-alpine [class^="ag-"]:focus,
.ag-theme-alpine [class^="ag-"]:after,
.ag-theme-alpine [class^="ag-"]:before {
  color: var(--white-color) !important;
}

.ag-header-row.ag-header-row-column {
  height: 100% !important;
}

.ag-theme-alpine .ag-header-icon {
  color: var(--white-color) !important;
}

div#root div.hw-grid.ag-theme-alpine .custom-header .sort-icons > span {
  color: var(--white-color) !important;
}

.ag-wrapper.ag-picker-field-wrapper,
.ag-popup > div,
.ag-text-field-input-wrapper input {
  background-color: var(--row-bg-hover-color) !important;
  &:focus {
    box-shadow: none !important;
  }
}

.ag-theme-alpine .ag-checked:after {
  color: var(--row-bg-hover-color) !important;
}

.ag-theme-alpine .ag-input-wrapper input {
  background-color: var(--row-bg-hover-color) !important;
}
// div#root div.hw-grid.ag-theme-alpine .custom-header .sort-icons > span,
// .ag-theme-alpine [class^="ag-"],
// .ag-theme-alpine [class^="ag-"]:focus,
// .ag-theme-alpine [class^="ag-"]:after,
// .ag-theme-alpine [class^="ag-"]:before {
//   color: var(--grid-theme-color) !important;
// }


// filter section

.ag-theme-alpine .ag-input-wrapper input, .ag-theme-alpine .ag-select .ag-picker-field-wrapper{
  background-color: var(--widgets-area-bg-color) !important;
  border: 1px solid var(--white-color) !important;
  box-shadow: none !important;
  outline: none !important;
  &:active, &:focus, &:hover{
    border: 1px solid var(--theme-color-2) !important;
  }
}

.ag-theme-alpine .ag-radio-button-input-wrapper.ag-checked{
    display: inline-block !important;
    width: 19px !important;
    height: 19px !important;
    padding: 2px !important;
    border-radius: 10px !important;
    background-color: var(--theme-color-2) !important;
    &::after{
      content: '' !important;
      color: #fff !important;
    }
}
@import "../../variables.scss";

.watch-list {
  min-width: 309px;
  padding: 5px;

  #modalHeaderBox {
    padding: 6px 0;
  }

  #modalDialogBox {
    z-index: 1;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: $widgets-area-bg-color !important;
  }

  #spinnerLoading {
    position: absolute;
    top: 40%;
    left: 40%;
    color: $foreground-color;
  }

  .action-buttons {
    color: $text-color;
    font-size: 11px;
    padding-bottom: 5px;

    label {
      margin-bottom: 0;
      margin-right: 2px;
    }

    .input-group {
      width: 150px;
      margin-right: 7px;

      input {
        background: transparent;
        border: 1px solid $foreground-color;
        border-radius: 5px 0 0 5px !important;
        color: $text-color;
        height: 20px;
        padding: 0 2px;
      }

      .input-group-append { 
        height: 20px;
        width: 24px;

        .input-group-text {
          background-color: $foreground-color;
          border-color: $foreground-color;
          padding: 0;

          svg {
            margin-right: 0;
          }
        }
      }
    }

    svg {
      color: $foreground-color;
      margin-right: 10px;
      height: 20px;
    }

    button {
      background-color: $foreground-color;
      border-color: $foreground-color;
      font-size: 12px;
      line-height: 1;
      margin-right: 5px;

      &:first-child {
        margin-left: 0;
      }

      &:not(:disabled):not(.disabled).active,
      &:not(:disabled):not(.disabled):active {
        background-color: $foreground-color;
        border-color: $foreground-color;
        opacity: 0.9;
      }
    }
  }

  .spacer-v {
    border-right: 1px solid $foreground-color;
    display: inline-block;
    height: 100%;
    margin-right: 5px;
    width: 0px;
  }

  .modalHeaderText {
    font-size: 12px;
    color: var(--text-color);
  }

  .modalInputBox {
    border-color: $foreground-color;
    background-color: inherit !important;
    font-size: 12px;
    color: var(--darkest-grey-modal);
    text-transform: uppercase;
  }

  .modalInputBox:focus {
    border-color: $foreground-color;
    box-shadow: none;
  }

  .modal-content {
    background-color: inherit;
  }

  .modal-btn {
    border-color: $foreground-color;
    font-size: 12px;
    color: var(--darkest-grey-modal);
  }

  .btn:hover {
    color: white;
  }

  .create-watch-list {
    margin-right: 10px;
  }
}
.content-date{
    color:var(--theme-white);
    font-weight: bold;
}
.content-date:hover{
    color: var(--foreground-color);
    font-weight: bold;
}

.datecolor{
    color:var(--theme-white);
}


.scrollable {
    // height: auto;
    max-height: 600px !important;
    overflow-y: auto;
}


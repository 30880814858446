@import "./variables.scss";

@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-grids/styles/material.css";

@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Assistant:wght@200;300;400;500;600;700;800&family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

html,
body {
  background-color: $widgets-area-bg-color;
  color: var(--text-color);
  height: 100%;
  margin: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: "Segoe UI", sans-serif !important;
}

header,
footer {
  background-color: var(--dark-grey);
}

header {
  height: 82px;
}

.headerOptions {
  font-size: 14px;
  color: $foreground-color;
}

#advertisementBox {
  font-family: "Segoe UI", sans-serif;
  font-size: 22px;
}

div#root {
  .form-control {
    background-color: var(--background-color);
    border: 0.5px solid $foreground-color;
    border-radius: 5px;
    box-shadow: none;
    color: var(--white-shade-anchor-color);
    font-size: 12px;
    padding: 1px 4px !important;

    &:focus {
      border: 0.5px solid $foreground-color;
      box-shadow: none;
    }

    &:disabled,
    &[readonly] {
      background-color: var(--background-color);
      opacity: 1;
      cursor: not-allowed;
    }
  }

  .price-positive {
    color: var(--success-color) !important;

    * {
      color: var(--success-color) !important;
    }
  }

  .price-negative {
    color: var(--danger-color) !important;
  }

  .text-underline:hover {
    text-decoration: underline;
    cursor: pointer;
    color: var(--foreground-color);
  }

  .hw-btn-link {
    color: $foreground-color;
    cursor: pointer;
    border-bottom: 1px dotted $foreground-color;
  }
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--scroll-track-color);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--darkest-grey-modal);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--medium-grey);
}

.show-password-eye-icon {
  height: 46px;
  width: 43px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  cursor: pointer;

  &.input-group-text {
    background-color: var(--background-color);
  }
}

svg {
  cursor: pointer;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.e-spinner-pane .e-spinner-inner .e-spin-material {
  stroke: var(--foreground-color);
}

.e-toolbar .e-tbar-btn:focus {
  background: var(--foreground-color) !important;
}

.bg-color-inherit {
  background-color: inherit !important;
}

html.theme-dark .tv-widget-ticker__item {
  background-color: #191F2A !important;
  margin: 0px 10px !important;
  border-radius: 10px !important;
}

.overflow-hide{
  overflow: hidden !important;
}

.MuiSvgIcon-root{
  width: .8em !important;
  height: .8em !important;
}
@import "../../variables.scss";

.shell-view {
  min-height: min-content !important;
  &.trans-shell {
    position: fixed !important;
    top: 55% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
  }
  &.gen-shell {
    position: absolute !important;
    top: 0% !important;
    left: 0% !important;
  }
  &.report-width{
    min-width: 400px !important;
    min-height: 300px !important;
  }
  // &.shell-height{
  //   display: table-row-group !important;
  // }

  .flex-view {
    background-color: var(--background-color);
    box-sizing: border-box;
    color: #ddd;
    border: 1px solid var(--border-new-color) !important;
    display: inline-block;
    height: 100%;
    width: 100%;

    &.widget-width {
      width: 680px !important;
    }

    &.main-widget-height {
      height: auto !important;
    }
    &.fc-control {
      display: flex !important;
      height: auto !important;
      width: auto !important;
      &.maintain-height{
        min-height: 670px !important;
      }
    }

    &.shell-overflow {
      overflow-y: scroll !important;
      overflow-x: hidden !important;
    }
  }

  &.box-shadow-shell .flex-view{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
    background-color: var(--widgets-area-bg-color) !important;
  }

  .toolbar {
    box-sizing: border-box;
    cursor: move;
    font-weight: 500 !important;
    &.form-toolbar{
      background-color: var(--background-color) !important;
    }

    &.locked {
      cursor: default;
    }

    .title {
      margin: 0 auto 0 5px;
    }

    .actions {
      .icon-wrapper {
        cursor: pointer;
        font-size: 20px;
        padding: 2px;
        margin-left: 4px;
      }

      .link-icon-contain{
        background-color: var(--background-color-2) !important;
        margin: 0px 2px 0px auto !important;
        border-radius: 7px !important;
        padding: 2px !important;
      }

      .linking-icon,
      .linking-icon:focus,
      .linking-icon:active,
      .linking-icon:hover {
        padding: 5px 3px !important;
        background-color: transparent !important;
        border: inherit;
      }

      .linking-icon-dropdown-menu {
        min-width: 50px;
        background-color: var(--background-color);
      }

      .linking-icon-dropdown-items {
        font-size: 12px;
      }

      .linking-icon-dropdown-items:hover {
        background-color: #c4c4c4;
        font-size: 12px;
      }

      .linking-icon::after {
        display: none;
      }

      .badge {
        transition: none;
      }

      button.linking-icon.dropdown-toggle {
        color: #ddd;
      }
    }

    .actions {
      margin: 0 3px 0 auto;
      .icon-widget {
        color: var(--foreground-color) !important;
        margin: 0 3px 0 auto !important;
        border-radius: 7px !important;
        padding: 1px !important;
        background-color: var(--background-color-2) !important;
        cursor: pointer !important;
      }
    }
  }

  .content {
    background-color: var(--background-color);
    box-sizing: border-box;
    height: 100%;
    overflow: hidden;
    width: 100%;
    &.form-box{
      background-color: var(--widgets-area-bg-color) !important; 
    }
    &.bg-order{
      background-color: var(--toolbar-bg-color) !important; 
    }
    &.shell-full-overflow{
      overflow: scroll !important;
    }
  }

  #dropdown-list {
    background-color: $widgets-area-bg-color;
    position: absolute;
    top: 35px;
    left: 90px;
    z-index: 1;
    padding: 10px 0;
    cursor: pointer;
  }

  .inner-text {
    color: var(--darkest-grey-modal);
    font-size: 12px;
    padding: 3px 10px;
  }

  .inner-text:hover {
    background-color: var(--background-color);
  }

  .dropdown_list-line {
    border-top: 1px solid #707070;
    margin: 0 0 5px 0;
  }
}

.shell-view.react-draggable {
  z-index: 999 !important;
  &.active-widget {
    z-index: 1000 !important;
  }
}

.toolbar {
  background-color: var(--toolbar-bg-color) !important;
  height: 30px !important;
  color: var(--white-color) !important;
  font-family: 'Poppins', sans-serif !important;
  font-size: 12px !important;
  line-height: normal !important;;
}
.workspace-rename {
  background-color: var(--widget-tabs-bg-color) !important;
  color: white;
  border-color: #e66c06;
  border-bottom: 1;
  border-top: none;
  border-right: none;
  border-left: none;
  font-size: 14px;
}

@import "../../variables.scss";

.quick-trade {
  color: var(--text-color);
  font-size: 12px;

  .all-controls {
    padding: 4px;

    .form-label {
      padding-left: 2px;
      margin-bottom: 0;
    }

    .first-row {
      margin-bottom: 10px;
    }
  }

  .form-group,
  .form-control {
    width: 100%;
  }

  .symbol-account-wrapper {
    width: 33.33%;
  }

  .position-wrapper {
    background-color: $widgets-area-bg-color;
    border: 1px solid #707070;
    color: var(--white-color);
    margin-left: 10px;

    >div {
      height: 100%;
    }
  }

  .order-info-wrapper>div {
    width: 33.33%;
  }

  .trade-field {
    height: 22px;
    text-transform: uppercase;
  }

  .trade-field:hover,
  .trade-field:focus {
    background-color: var(--background-color);
  }

  .w-100 {
    width: 100%;
  }

  .spacer-h {
    width: 10px;
  }

  .spacer-v {
    height: 5px;
  }

  .ask,
  .bid {
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    cursor: pointer;
    height: 40px;
  }

  .ask {
    border-color: var( --success-color);
  }

  .bid {
    border-color: #C43019;
    width: calc(100% - 5px);
  }

  .action-buttons {
    height: 50px;
    margin-top: 5px;

    .buy-btn {
      font-size: 14px;
      width: 100%;
      background-color: #04478e;
      border-radius: 0;
      box-shadow: none;
      border: none;
    }

    .buy-btn:hover,
    .buy-btn:focus,
    .buy-btn:active {
      background-color: #04478e !important;
      opacity: 0.9;
    }

    .sell-button-wrapper {
      background-color: red;
    }

    .sell-btn {
      font-size: 14px;
      width: 100%;
      background-color: #c43019;
      border-radius: 0;
      box-shadow: none;
      border: none;
    }

    .sell-btn:hover,
    .sell-btn:focus,
    .sell-btn:active {
      background-color: #c43019 !important;
      opacity: 0.9;
    }

    .disabledBtn {
      cursor: not-allowed;
    }
  }
}
.marquee-container {
  font-size: 16px;
  margin-top: 13px 10px 0 10px;
  text-align: center;

  .ad {
    margin: 0 50px;

    &.flash {
      animation: blinker 1.2s linear infinite;
      color: #ff0000;
      font-weight: 500;
    }

    &.urgent {
      font-weight: 500;
      color: var(--foreground-color);
    }
  }
}

@keyframes blinker {
  0% {
    opacity: 1;
  }
  49% {
    opacity: 1;
  }
  60% {
    opacity: 0;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

$background-color: var(--row-bg-color);
$header-bg-color: var(--header-bg-color);
$row-bg-color: var(--row-bg-color);
$row-bg-hover-color: var(--row-bg-hover-color);
$border-row-color: #474747;

.e-grid {
  color: var(--white-color) !important;
  font-family: "Segoe UI", sans-serif !important;
}
.e-grid .e-headercell {
  color: var(--white-color);
  background-color: $header-bg-color;
  line-height: 25px !important;
  border-bottom: 2px solid white !important;
  .e-grid .e-headercell :hover {
    background-color: $background-color;
  }
  .e-headertext {
    font-size: 1.2em;
  }
}
.e-grid .e-gridheader {
  border: 2px solid $background-color;
}
.e-toolbar {
  background: $background-color;
  border-top: 1px solid #404056 !important;
}

.e-grid .e-toolbar-items {
  background: $background-color;
}
.e-grid td.e-active {
  background-color: transparent !important;
  color: var(--white-color) !important;
}

.e-grid tr.e-active {
  background: none !important;
}

.e-content {
  height: 98% !important;
  overflow-y: scroll !important;
  background-color: var(--background-color) !important;
  // &::-webkit-scrollbar {
  //   display: none !important;
  // }
}

.hw-admin-grid.ag-theme-alpine::-webkit-scrollbar {
  display: none !important;
}

tr.e-row {
  &:nth-child(odd) {
    background-color: $row-bg-color;
    line-height: 20px !important;
  }
  &:nth-child(even) {
    background-color: $row-bg-hover-color;
    line-height: 20px !important;
  }
}
td.e-rowcell {
  color: var(--grid-theme-color) !important;
  &.cell-danger-style {
    text-align: left !important;
  }
  &.cell-success-style {
    text-align: left !important;
  }
}

td {
  &.cell-danger-style {
    color: red !important;
    background-clip: content-box !important;
    line-height: 25px !important;
  }
  &.cell-success-style {
    color: green !important;
    background-clip: content-box !important;
    line-height: 25px !important;
  }
}

.e-field {
  color: white !important;
}
.e-input-group {
  color: white !important;
}
.e-input-group-icon {
  color: white !important;
}
.e-gridheader {
  padding-right: 0 !important;
  border: none !important;
}

.e-grid.e-gridhover
  tr.e-row:hover
  .e-rowcell:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell),
.e-grid.e-gridhover tr.e-frozenhover {
  background-color: $border-row-color !important;
}

tr.e-row:hover td.cell-danger-style,
tr.e-row:hover td.cell-success-style {
  background-clip: border-box !important;
}

tr.e-row:hover td.e-active.cell-danger-style,
tr.e-row:hover td.e-active.cell-success-style {
  color: var(--white-color) !important;
}

.e-toolbar .e-toolbar-items .e-toolbar-item.e-overlay {
  background: none;
}

.e-toolbar {
  .e-tbar-btn {
    background: var(--foreground-color);
    color: var(--white-color);
    padding: 0 10px;
    &:hover {
      opacity: 0.9;
      background: var(--foreground-color);
    }
  }
}
.e-toolbar .e-toolbar-items .e-toolbar-item.e-overlay .e-tbar-btn-text,
.e-toolbar .e-toolbar-item .e-tbar-btn.e-btn .e-icons.e-btn-icon,
.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text {
  color: var(--white-color);
}
.e-grid .e-headercell,
.e-grid .e-rowcell,
.e-grid .e-groupcaption,
.e-grid .e-indentcell,
.e-grid .e-recordplusexpand,
.e-grid .e-recordpluscollapse,
.e-grid .e-detailrowcollapse,
.e-grid .e-detailrowexpand,
.e-grid .e-detailindentcell,
.e-grid .e-detailcell {
  border-color: $border-row-color !important;
}

.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn {
  padding: 0 10px;
}
.e-grid .e-toolbar {
  border: none;
}

.e-grid {
  border-left: 1px solid var(--header-bg-color);
  border-bottom: none;
}

.e-checkbox-wrapper.e-checkbox-disabled .e-frame,
.e-css.e-checkbox-wrapper.e-checkbox-disabled .e-frame {
  background-color: var(--dark-medium-grey);
  border-color: var(--dark-medium-grey);
  color: var(--white-color);
}

.e-checkbox-wrapper.e-checkbox-disabled .e-frame.e-check,
.e-css.e-checkbox-wrapper.e-checkbox-disabled .e-frame.e-check {
  background-color: var(--darkest-grey-modal) !important;
  border-color: var(--background-color) !important;
}

.e-grid .e-rowcell {
  padding: 1px 15px;
}

.e-grid .e-headercell {
  height: 25px;
}

.e-grid .e-headercelldiv {
  height: auto;
}
.e-input[disabled],
.e-input-group .e-input[disabled],
.e-input-group.e-control-wrapper .e-input[disabled] {
  -webkit-text-fill-color: var(--white-color);
}
.e-grid .e-headercelldiv {
  padding: 0 !important;
}
.e-toolbar-item.e-search-wrapper .e-input-group.e-search.e-input-focus:after,
.e-toolbar-item.e-search-wrapper .e-input-group.e-search.e-input-focus:before {
  background: var(--foreground-color) !important ;
}
.e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left) {
  border-bottom: 1px solid;
  border-bottom-color: var(--white-color) !important ;
}
input#grid_searchbar::placeholder {
  color: var(--white-color) !important;
}

div.shell-view .fc-table div.content > div {
  display: contents !important;
  & > div {
    display: contents !important;
  }
}

.e-grid .e-gridheader .e-icons:not(.e-icon-hide):not(.e-check):not(.e-stop) {
  position: absolute !important;
  right: 5px !important;
  color: var(--row-bg-color) !important;
  font-size: 15px !important;
  font-weight: 900 !important;
  margin: -20px auto !important;
}

.e-flmenu .e-dlg-content,
.e-contextmenu-wrapper ul,
.e-contextmenu-container ul,
.e-excelfilter .e-footer-content,
.e-dlg-content,
.e-input-group.e-control-wrapper {
  background-color: var(--row-bg-hover-color) !important;
  color: var(--dark-input-color)9 !important;
  padding-left: 0px !important;
}

.e-excelfilter .e-contextmenu-wrapper ul li .e-menu-icon,
.e-excelfilter .e-contextmenu-container ul li .e-menu-icon span,
.e-btn.e-flat,
.e-css.e-btn.e-flat,
.e-checkbox-wrapper .e-label,
.e-css.e-checkbox-wrapper .e-label {
  color: var(--dark-input-color)9 !important;
}

.e-ddl.e-input-group.e-control-wrapper .e-ddl-icon::before {
  padding-right: 10px !important;
}

.e-disabled {
  color: var(--medium-dark-black) !important;
}

.e-grid .e-icon-filter,
.e-grid-menu .e-icon-filter {
  content: url("./../../../Images/filter-solid.svg") !important;
  color: white !important;
  margin-left: 10px !important;
}

.e-pagerdropdown .e-input-group.e-control-wrapper {
  background-color: var(--dark-input-color)9 !important;
  color: var(--row-bg-hover-color) !important;
  padding-left: 10px !important;
}

.e-footer-content {
  background-color: var(--row-bg-hover-color) !important;
}

span .e-input-group ::before {
  background-color: var(--row-bg-hover-color) !important;
}

.e-grid .e-pager {
  position: fixed !important;
  bottom: 45px !important;
  opacity: 1 !important;
  width: 99.8%;
}

// .account .e-grid {
//   height: 102% !important;
// }

// .user .e-grid {
//   height: 90% !important;
// }

// .group .e-grid {
//   height: 81% !important;
// }

// .firm .e-grid {
//   height: 89% !important;
// }

.e-grid .e-gridcontent.e-responsive-header {
  height: calc(90% - 4rem) !important;
}

.accountperform .e-grid .e-gridcontent.e-responsive-header {
  height: calc(99.5% - 4rem) !important;
}

.firm .e-grid .e-pager {
  bottom: 37px !important;
}

.e-xlflmenu {
  & .e-dlg-header-content {
    background-color: var(--row-bg-hover-color) !important;
    & .e-dlg-header {
      color: var(--dark-input-color) !important;
    }
    & button .e-dialog .e-icon-dlg-close {
      color: var(--dark-input-color) !important;
    }
    & .e-radio + label .e-label {
      color: var(--dark-input-color) !important;
    }
  }
  .e-xlfl-maindiv {
    margin-left: 20px !important;
  }
  .e-radio + label .e-label {
    color: var(--dark-input-color) !important;
  }
  .e-xlfl-radio {
    padding: 10px 0px !important;
  }
  .e-icon-dlg-close {
    color: var(--dark-input-color) !important;
  }
  .e-xlfl-table {
    margin: 10px 0px !important;
  }
}

.searchresult .e-grid .e-pager {
  position: relative !important;
  opacity: 1 !important;
  width: 99.95%;
}

.searchresult .e-content {
  margin-bottom: 45px !important;
}

.searchresult .e-grid {
  height: 100% !important;
}

.group .e-grid .e-content {
  height: 99% !important;
}

.group .e-grid .e-pager {
  bottom: 0 !important;
}

.group .e-grid .e-gridcontent.e-responsive-header {
  height: calc(91% - 4rem) !important;
}

.firm .e-grid .e-pager {
  bottom: 0 !important;
}

.firm .e-grid .e-gridcontent.e-responsive-header {
  height: calc(91% - 4rem) !important;
}

// .e-grid.e-resize-lines .e-gridheader th.e-headercell.e-lastcell .e-rhandler{
//   display: none !important;
// }

.e-rowcell button {
  background-color: transparent !important;
  color: var(--grid-theme-color) !important;
  border: 1px solid var(--medium-dark-black);
  display: flex;
  justify-content: center;
}

.e-rowcell button:hover {
  background-color: var(--background-color) !important;
  color: var(--grid-theme-color) !important;
  border: 1px solid transparent;
}

td.e-rowcell {
  &.text-success {
    color: rgb(0, 209, 0) !important;
  }
  &.text-danger {
    color: red !important;
  }
  &.text-gold {
    color: rgb(233, 176, 32) !important;
  }
  &.bg-orrange {
    text-align: center !important;
    background-clip: content-box !important;
    background-color: var(--orange-btn-color) !important;
    color: var(--grid-theme-color) !important;
  }
  &.bg-success {
    text-align: center !important;
    background-clip: content-box !important;
    background-color: rgb(0, 209, 0) !important;
    color: var(--grid-theme-color) !important;
  }
  &.bg-danger {
    text-align: center !important;
    background-clip: content-box !important;
    background-color: rgb(255, 35, 35) !important;
    color: var(--grid-theme-color) !important;
  }
}

tr:hover td.e-rowcell {
  &.bg-orrange {
    background-clip: content-box !important;
    background-color: transparent !important;
    color: goldenrod !important;
  }
  &.bg-success {
    background-clip: content-box !important;
    background-color: transparent !important;
    color: rgb(0, 209, 0) !important;
  }
  &.bg-danger {
    background-clip: content-box !important;
    background-color: transparent rgb(255, 35, 35) !important;
    color: rgb(255, 35, 35) !important;
  }
}

div#root div.hw-grid.ag-theme-alpine .ag-cell.price-positive {
  color: rgb(0, 209, 0) !important;
}

div#root div.hw-grid.ag-theme-alpine .ag-cell.price-negative {
  color: rgb(255, 35, 35) !important;
}

div#root div.hw-grid.ag-theme-alpine .ag-cell.price-equal {
  color: var(--white-color) !important;
}

div#root div.hw-grid.ag-theme-alpine .ag-cell.price-cancel {
  color: var(--foreground-color) !important;
}

.e-pager {
  background-color: var(--row-bg-hover-color) !important;
  color: var(--white-color) !important;
}

.e-pager .e-pagercontainer {
  background-color: var(--row-bg-hover-color) !important;
  border-color: var(--row-bg-hover-color) !important;
}

.e-pager .e-currentitem {
  background-color: var(--foreground-color) !important;
}

.e-pager .e-currentitem,
.e-pager .e-currentitem:hover {
  background: var(--foreground-color) !important;
  color: var(--white-color);
  opacity: 1;
}

.e-pager .e-numericitem {
  background: var(--row-bg-hover-color) !important;
  border-right-color: var(--row-bg-hover-color) !important;
  color: var(--white-color) !important;
}

.e-pager .e-numericitem:hover {
  background: var(--orange-btn-color) !important;
  border-right-color: var(--orange-btn-color) !important;
  color: var(--row-bg-hover-color) !important;
}

.e-pager .e-numericitem:focus,
.e-pager .e-currentitem:focus,
.e-pager .e-numericitem.e-active,
.e-pager .e-currentitem.e-active {
  background: var(--foreground-color) !important;
  color: var(--white-color);
  opacity: 1;
}

.e-pager .e-pagerdropdown .e-input-group.e-control-wrapper {
  background-color: var(--row-bg-color) !important;
  color: var(--white-color) !important;
  padding-left: 10px !important;
}

.e-pager .e-firstpage,
.e-pager .e-prevpage,
.e-pager .e-nextpage,
.e-pager .e-lastpage {
  background-color: var(--foreground-color) !important;
}

.e-pager .e-firstpagedisabled,
.e-pager .e-prevpagedisabled,
.e-pager .e-nextpagedisabled,
.e-pager .e-lastpagedisabled {
  background-color: var(--background-color) !important;
  color: var(--pagination-bg-color) !important;
}

.e-grid .e-pager div.e-icons {
  color: var(--white-color) !important;
}

.context-menu {
  background: var(--toolbar-bg-color);
  border: 1px solid var(--border-new-color);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1) !important;
  cursor: pointer;
  position: fixed;
  z-index: 1000;
  list-style-type: none;
  padding: 0px 2px !important;
}

.menu-item {
    user-select: none;
    padding: 5px 0px !important;
    border-bottom: 1px solid var(--border-new-color) !important;
    &:last-child{
        border-bottom: none !important;
    }
  .link-menu {
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
    padding: 4px 10px !important;
    font-family: "Assistant", sans-serif !important;
    letter-spacing: 1px !important;
    color: var(--white-color) !important;
    font-size: 14px !important;
    line-height: 20px !important;
    font-weight: 500 !important;
    text-decoration: none !important;
    &:hover,
    &:focus {
      background-color: var(--foreground-color) !important;
      text-decoration: none !important;
    }
    .img-icon{
        margin-right: 10px !important;
        width: 16px !important;
        height: 16px !important;
    }
  }
}

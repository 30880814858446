// $sidebar-bg-color: #201c3c;
$sidebar-bg-color: var(--background-color);
$submenu-bg-color: var(--background-color);
$icon-bg-color: var(--background-color);

@import "~react-pro-sidebar/dist/scss/styles.scss";

.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu
  .pro-inner-list-item
  .pro-inner-item:before {
  display: none !important;
}

.pro-sidebar .pro-menu > ul > .pro-sub-menu.open {
  background: var(--background-color-2) !important;
}

.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu
  .pro-inner-list-item
  .pro-inner-item {
  padding: 8px 15px 8px 55px;
  margin: 0px !important;
}

.pro-menu {
  padding-top: 10px;
  padding-bottom: 10px;
  flex: 1;

  > ul {
    > .pro-sub-menu {
      > .pro-inner-list-item {
        position: relative;
        background-color: var(--background-color-2) !important;

        > div > ul {
          padding-top: 0;
          padding-bottom: 0;
        }
      }

      &.open {
        background: var(--background-color);
      }
    }
  }

  a {
    text-decoration: none;
    color: $sidebar-color;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: transparent;
    }

    &:hover {
      color: $highlight-color;
    }
  }

  .pro-menu-item {
    font-size: 15px;

    &.active {
      color: $highlight-color;

      a {
        color: var(--white-color);
      }
    }

    .pro-inner-item {
      &:hover, &:focus, &:active, &.active, &:visited {
        border-radius: 10px !important;
        background: var(--foreground-color) !important;
        box-shadow: 4px 4px 32px 0px rgba(103, 90, 255, 0.07) !important;
        & .pro-icon-wrapper{
          fill: none !important;
          background-color: transparent !important;
          color: var(--white-color) !important;
          svg path{
            fill: var(--white-color) !important;
          }
        }
        
      }
    }
  }
}

.pro-sidebar.collapsed
  .pro-menu
  > ul
  > .pro-menu-item.pro-sub-menu
  > .pro-inner-list-item
  > .popper-inner {
  position: relative !important;
  background-color: var(--background-color-2) !important;
  padding-left: 0px;
}

.pro-sidebar.collapsed
  .pro-menu
  > ul
  > .pro-menu-item.pro-sub-menu
  .pro-inner-list-item
  .pro-inner-item {
  padding: 8px 30px 8px 5px;
  border: 1px solid !important;
}

.pro-sidebar .sidebar-toggle {
  font-size: 24px;
}

.custom-control-input {
  cursor: pointer;

  ~ .custom-control-label {
    cursor: pointer;
  }
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: var(--white-color) !important;
  border-color: var(--foreground-color) !important;
  background-color: var(--foreground-color) !important;
}

.custom-control-input:checked ~ .custom-control-label {
  color: var(--white-color);
}

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul {
  padding: 0;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  padding: 0;
}

.pro-sidebar .pro-menu .pro-menu-item {
  padding: 2px !important;
}

.pro-sidebar.collapsed .pro-menu .popper-inner .pro-menu-item {
  padding: 5px !important;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
  font-family: "Poppins", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: var(--white-color) !important;
  opacity: .92 !important;
}

.pro-sidebar > .pro-sidebar-inner, .pro-sidebar .pro-menu.shaped .pro-menu-item > .pro-inner-item > .pro-icon-wrapper{
  background: var(--background-color-2) !important;
  background-color: var(--background-color-2) !important;
}

.pro-sidebar .pro-menu.shaped .pro-menu-item > .pro-inner-item > .pro-icon-wrapper{
  background: transparent !important;
  background-color: transparent !important;
}

.pro-sidebar .pro-menu.shaped .pro-menu-item > .pro-inner-item:hover{
  .pro-icon-wrapper{
    background: var(--foreground-color) !important;
    background-color: var(--foreground-color) !important;
  }
  .pro-arrow{
    color: var(--white-color) !important;
  }
}

.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-item{
  padding: 0px 20px !important;
  &:hover{
    background-color: var(--foreground-color) !important;
    border-radius: 10px !important;
  }
}

.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  border: 1px solid var(--border-new-color) !important;
.pro-inner-item{
  border: none !important;
}}

.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-item .pro-icon-wrapper{
  margin-right: 0px;
}

.pro-sub-menu .pro-inner-list-item .pro-inner-item{
  &:hover, &:active, &.active, &:focus, &:visited{
    background-color: var(--background-color-2) !important;
    background: var(--background-color-2) !important;
    color: var(--foreground-color) !important;
  }
}

.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu {
  position: relative;
  padding-left: 0px !important;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper .pro-arrow{
  display: none !important;
}

.pro-sidebar.collapsed .pro-menu-item.pro-sub-menu{
  &:hover, &:focus, &:active, &.active, &:visited{
    .pro-icon-wrapper{
      background-color: var(--foreground-color) !important;
      border-radius: 10px !important;
    }
  }
}

.pro-sidebar .pro-menu .react-slidedown.pro-inner-list-item .pro-menu-item > .pro-inner-item{
    .pro-item-content{
      color: var(--darkest-grey-modal) !important;
    }
}

.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item .pro-inner-item{
  padding: 3px !important;
  border-radius: 5px !important;
}

.pro-sidebar .pro-menu .react-slidedown.pro-inner-list-item .pro-menu-item > .pro-inner-item, .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item{
  &:hover, &:focus, &:active, &.active, &:visited{
    background-color: var(--border-new-color) !important;
    box-shadow: 4px 4px 32px 0px transparent !important;
    .pro-item-content{
      color: var(--white-color) !important;
    }
  }
}

.pro-menu-item.pro-sub-menu.active > .pro-inner-item{
    border-radius: 10px !important;
    background: var(--foreground-color) !important;
    box-shadow: 4px 4px 32px 0px rgba(103, 90, 255, 0.07) !important;
}

.pro-sidebar.collapsed .pro-inner-item .pro-item-content{
  margin-left: 20px !important;
}

.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu:hover > .pro-inner-list-item{
  visibility: hidden;
}

.pro-sidebar.collapsed .pro-menu-item.pro-sub-menu.active > .pro-inner-item .pro-icon-wrapper{
  border-radius: 10px !important;
  background: var(--foreground-color) !important;
  box-shadow: 4px 4px 32px 0px rgba(103, 90, 255, 0.07) !important;
}

.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu:hover.active > .pro-inner-list-item{
  visibility: visible;
}

.pro-sidebar.collapsed .pro-menu-item.pro-sub-menu.active > .pro-inner-item{
  border-radius: 0px !important;
  background: transparent !important;
  box-shadow: none !important;
}

.pro-sidebar.collapsed .pro-menu-item.pro-sub-menu {
  padding: 10px 0px !important;
}
@import "../../variables.scss";

$section-padding: 8px;

.login-modal {
  .modal-dialog {
    width: 460px;

    .modal-content {
      background-color: var(--row-bg-hover-color) !important;
    }

    .modal-header {
      padding: $section-padding;
      padding-left: 130px !important;

      .logo-wrapper {
        text-align: center;
        width: 100%;

        img {
          display: flex !important;
          justify-content: center !important;
          width: 100% !important;
        }
      }
    }

    .modal-body {
      padding: 30px 20px !important;

      .form-control {
        font-size: 16px;
      }

      .forgot-password {
        color: $foreground-color;
        font-weight: 500;
      }
    }

    .modal-footer {
      padding: $section-padding;
      border: 0 !important;
    }
  }

  .login-links-text {
    color: var(--theme-color-2);
    font-size: 14px;
  }

  .signup-text {
    color: #f39222;
  }

  .cursor-pointer {
    cursor: pointer;
  }
}

.login-modal .form-label {
  color: #ff8d30 !important;
  margin-bottom: 0.3rem !important;
}

.login-modal .form-check-label {
  color: #ff8d30 !important;
  margin-left: 0.3rem !important;
}

.form-control {
  border: 1px solid rgba(255, 255, 255, 0.658) !important;
  border-radius: 0px !important;
  /* font-weight: bold; */
  padding: 0.3rem 0.95rem !important;
  color: var(--theme-color-2) !important;
  display: flex !important;
  border-radius: 0.175rem !important;
  align-items: center !important;
  border-radius: 0.175rem !important;

  &:focus {
    background-color: transparent !important;
    border: 1px solid var(--theme-color-2) !important;
    color: var(--white-color) !important;
  }
}

.btn-form {
  background-color: var(--foreground-color) !important;
  border-color: var(--foreground-color) !important;
  color: var(--white-color);
  font-weight: bold;
  padding-top: 3px;
  padding-bottom: 3px;
  margin: 10px;
  border-radius: 2px !important;
  &.disable{
      background-color: var(--darkest-blue-color) !important;
      color: var(--medium-grey) !important;
      border-color: var(--row-bg-hover-color) !important;
  }
  &:disabled{
      background-color: var(--darkest-blue-color) !important;
      color: var(--medium-grey) !important;
      border-color: var(--row-bg-hover-color) !important;
      cursor: not-allowed;
  }
}

.btn-form:hover {
  background-color: var(--white-color) !important;
  border-color: var(--widgets-area-bg-color) !important;
  color: var(--widgets-area-bg-color) !important;
}

.btn-form:focus {
  box-shadow: none !important;
}
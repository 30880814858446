@import "../../variables.scss";

.footersection{
  position: fixed !important;
  bottom: 0 !important;
  width: 100% !important;
}
.footer {
  background-color:#0b0303;
  // border-bottom: 1px solid white;
  // border-top: 1px solid white;
  color: white;
  font-size: 10px;

  .copy-right-info {
    margin-left: 12px;
  }

  .social-icons {
    font-size: 12px;
    margin-left: 18px;
    min-width: 135px !important;

    a {
      margin-right: 18px;
    }
  }

  .help-center-wrapper {
    margin-right: 15px;
    min-width: 420px !important;

    .help-center {
      background-color: $foreground-color !important;
      border-radius: 0px !important;
      border: none !important;
      color: var(--white-color) !important;
      font-family: ProximaNova;
      font-size: 14px;
      height: 49px;
      justify-content: center;
      line-height: 49px;
      padding: 5;
      text-align: center;
      text-decoration: none;
      width: 120px;

      &:hover {
        background-color: black;
        border-radius: 2px;
        cursor: pointer;
        padding: 5;
      }
    }

    .connection-status {
      font-size: 14px;

      svg {
        font-size: 16px;
        margin-left: 3px;
        margin-right: 7px;

        &.is-connected {
          fill: $foreground-color;
        }
      }
    }

    .live-chat {
      width: 64px;

      iframe {
        height: 40px !important;
        width: 40px !important;
      }
    }
  }

  .quote-media-link {
    filter: brightness(0) invert(1);
    &:hover{
    cursor: pointer;
  }}
}

@import "../../variables.scss";

.ladder-view {
  #mainheaderLadderView {
    overflow-y: scroll !important;
  }

  .ladderTextLabel {
    font-size: 14px;
    color: #C4C4C4;
  }

  .ladderTextLabel:focus {
    font-size: 14px;
    color: #C4C4C4;
  }

  .inputStyle {
    padding: 0 10px !important;
  }

  .inputStyleColor {
    color: var(--darkest-grey-modal) !important;
  }

  .btnTimesSalesLadderView {
    background-color: $foreground-color;
    color: var(--white-color);
    font-size: 14px;
  }

  .qtyBtnLadderView {
    border-color: $foreground-color;
    color: #C4C4C4;
    font-size: 14px;
  }
}
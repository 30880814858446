@font-face {
  font-family: "Gilroy";
  src: url("./fonts/Gilroy/Gilroy-Light.ttf");
}

@font-face {
  font-family: "ProximaNova";
  src: url("./fonts/ProximaNova-Regular.ttf");
}

@font-face {
  font-family: "Lato";
  src: url("./fonts/Lato/Lato-Regular.ttf");
}

@font-face {
  font-family: 'Segoe UI';
  src: local('Segoe UI Regular'), local('Segoe-UI-Regular'),
      url('./fonts/SegoeUI/SegoeUI.woff2') format('woff2'),
      url('./fonts/SegoeUI/SegoeUI.woff') format('woff'),
      url('./fonts/SegoeUI/SegoeUI.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

body {
  color: #d8dee6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import 'bootstrap/dist/css/bootstrap.min.css';

.btn-outline-primary {
  background-color: black !important;
  border: 0;
  color: #ccc;
}

.btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 !important;
}

.dropdown-menu {
  background-color: black;
}

.dropdown-item {
  color: #ccc;
}

.close {
  color: white
}

.close:hover {
  color: #f39222;
  text-shadow: 0 1px 0 #f39222;
}
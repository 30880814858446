@import "../../variables.scss";

.time-and-sales {
  min-width: 310px !important;
  min-height: 400px !important;
  padding: 5px;

  .section-header {
    background-color: var(--background-color);
    color: var(--white-shade-anchor-color);
    font-size: 12px;
    padding-bottom: 5px;
  }

  .symbol-textfield {
    width: 90%;
    height: 25px;
    background-color: var(--background-color);
    border-color: $foreground-color;
    font-size: 12px;
    color: var(--darkest-grey-modal);
    margin-top: 2px;
    margin-left: 0px !important;
    text-transform: uppercase;
  }

  .symbol-textfield:focus {
    background-color: var(--background-color);
    border-color: $foreground-color;
    box-shadow: none;
  }

  .form-label {
    line-height: 9px;
  }

  .form-group {
    margin-top: 15px;
  }
}

@import "../../variables.scss";

.top-movers {
  font-size: 14px;
  margin: 5px;

  .top-movers-field {
    width: calc(100% - 50px);
    background-color: var(--background-color);
    border-color: $foreground-color;
    font-size: 12px;
    color: var(--darkest-grey-modal);
  }
}
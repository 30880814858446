.account-summary {
  box-sizing: border-box;
  font-size: 12px;
  line-height: 2;
  min-height: 455px !important;
  min-width: 260px !important;
  padding: 0 10px 5px 10px;

  > div {
    border-bottom: 1px solid var(--medium-dark-black);
  }
}
